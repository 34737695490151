<template>
  <main
    class="d-flex flex-column col-lg-4 col-6 px-0 fu-deal__column"
    style="overflow: hidden"
  >
    <create-rent-modal
      :is-loading="isLoading"
      :update="false"
      :view-only="true"
    ></create-rent-modal>
    <h5 class="text-capitalize fu-deal__column-title">Client rents</h5>
    <h6 class="mt-5">All client rents</h6>

    <div class="px-3 fu-car-rent-tall-wrapper pr-4">
      <b-row
        class="border-bottom p-1 mr-2 d-flex align-items-baseline justify-content-between"
        v-for="rent in rents"
        :key="rent.id"
      >
        <div>{{ rent.brand }} {{ rent.model }}</div>
        <div class="ml-4">
          {{ dateFormatter(rent.from) }} - {{ dateFormatter(rent.to) }}
        </div>
        <div>
          <button
            class="col-12 px-0 mr-2 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
            @click="openViewRentModal(rent)"
          >
            <i class="far fa-eye ml-lg-3 ml-0 row-icon"></i>
          </button>
        </div>
      </b-row>
    </div>
  </main>
</template>

<script>
import CreateRentModal from "@/components/cars/car/rent/CreateRentModal";
export default {
  name: "ClientRents",
  components: {
    CreateRentModal,
  },
  data() {
    return {
      selectedRent: {},
      isLoading: false,
    };
  },
  computed: {
    rents() {
      return this.$store.state.client.clientData.rents;
    },
  },
  methods: {
    dateFormatter(date) {
      return `${new Date(date).toLocaleDateString()}`;
    },
    openViewRentModal(rent) {
      this.$store.commit("car/SET_RENT_DATA", rent);
      this.$nextTick(() => {
        this.$bvModal.show("createRentModal");
      });
    },
  },
};
</script>

<style scoped></style>
