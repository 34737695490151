<template>
  <div>
    <div
      v-if="response && response.length > 0"
      class="d-flex flex-column filter-board__bottom"
      :class="noBorder"
    >
      <div
        v-for="(res, index) in response"
        :key="`${typeCode}-${index}-response`"
        class="d-flex flex-wrap filter-board__response"
        :class="{ 'border-bottom-0': index === response.length - 1 }"
      >
        <span
          ><i
            class="far"
            :class="[
              `fa-file-${
                res['categoryCode'] === 'expense'
                  ? 'export expense'
                  : 'import income'
              }`,
            ]"
          ></i>

          <span class="ml-3">{{ res.label }}: {{ res.data }}€</span>
        </span>
      </div>

      <div v-if="total && total.label" class="d-flex pt-3">
        <span> {{ total.label }}: </span>
        <span class="ml-3">
          {{ total.data }}€
          <i v-if="totalExp" class="far fa-file-import green ml-2"></i
        ></span>
      </div>
      <div v-if="totalExp && totalExp.label" class="d-flex pt-1">
        <span> {{ totalExp.label }}: </span>
        <span class="ml-3">
          {{ totalExp.data }}€ <i class="far fa-file-export red ml-2"></i
        ></span>
      </div>
      <div v-if="totalDifference && totalDifference.label" class="d-flex pt-1">
        <span> {{ totalDifference.label }}: </span>
        <span class="ml-3">
          {{ totalDifference.data }}€
          <i
            class="far ml-2"
            :class="[
              `fa-file-${
                totalDifference.categoryCode === 'expense'
                  ? 'export expense red'
                  : 'import income green'
              }`,
            ]"
          ></i
        ></span>
      </div>
    </div>
    <div
      v-else
      class="d-flex align-items-center justify-content-center filter-board__bottom"
      :class="noBorder"
    >
      <h4>No analytics found for this case</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterResponse",
  props: {
    typeCode: {
      type: String,
      default: "",
    },
    total: {
      type: Object,
      default: () => {},
    },
    totalDifference: {
      type: Object,
      default: () => {},
    },
    totalExp: {
      type: Object,
      default: null,
    },
    response: {
      type: Array,
      default: () => [],
    },
    noBorder: {
      type: String,
      default: "",
    },
  },
};
</script>
