<template>
  <b-modal
    id="documentsModal"
    ref="documentsModal"
    centered
    content-class="fu-modal"
    header-class="fu-modal__header fu-modal__submit-header"
    body-class="fu-modal__body"
    footer-class="fu-modal__footer"
    @hidden="closeModal"
  >
    <document-upload-modal
      :type="type"
      @addDocument="addDocument"
    ></document-upload-modal>
    <h5 class="pb-3 text-capitalize fu-deal__column-title">Documents</h5>
    <div class="mt-5">
      <div v-if="documents.length">
        <div v-for="(document, index) in documents" :key="document.id">
          <div class="d-flex justify-content-between pr-2">
            <a :href="getDocumentUrl(document.url)" target="_blank">{{
              document.filename
            }}</a>
            <button
              v-if="!onlyReadClient"
              class="col-2 px-0 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
              @click="removeDocument(index)"
            >
              <i class="far fa-trash-alt ml-lg-3 mr-1"></i>
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <h6>No documents yet...</h6>
      </div>
      <div class="d-flex justify-content-center">
        <fu-button
          v-if="!onlyReadClient"
          @click="uploadDocuments"
          title="Upload documents"
          id="uploadDocumentsButton"
        >
        </fu-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DocumentUploadModal from "@/components/cars/car/rent/DocumentUploadModal";

export default {
  name: "DocumentsModal",
  props: {
    type: {
      type: String,
      required: true,
    },
    onlyReadClient: {
      type: Boolean,
      default: false,
    },
    documents: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DocumentUploadModal,
  },
  methods: {
    getDocumentUrl(url) {
      return process.env.VUE_APP_AWS_BUCKET_URL + url;
    },
    removeDocument(index) {
      const conf = confirm("Do you really want to delete this file?");
      if (conf) this.$emit("removeDocument", index);
    },
    uploadDocuments() {
      this.$bvModal.show("documentUploadModal");
    },
    addDocument(value) {
      this.$emit("addDocument", value);
    },
    closeModal() {
      this.$refs.documentsModal.hide();
    },
  },
};
</script>

<style scoped></style>
