<template>
  <div ref="dealDetailsWrapper" class="col-lg-3 col-6 px-0 fu-deal__column">
    <h5 class="pb-3 text-capitalize fu-deal__column-title">Client details</h5>
    <validation-observer
      ref="clientDetailsForm"
      tag="div"
      class="col-12 pl-0 mb-4 fu-deal__column-wrapper"
    >
      <create-client-form
        :only-read-client="onlyReadClient"
        :is-loading="isLoading"
        form-class="d-flex flex-wrap col-12 px-0 mb-4"
      ></create-client-form>
    </validation-observer>
  </div>
</template>

<script>
import CreateClientForm from "@/components/clients/client/CreateClientForm";
export default {
  name: "ClientDetails",
  components: { CreateClientForm },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    onlyReadClient: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
