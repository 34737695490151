<template>
  <main
    class="d-flex flex-column col-lg-4 col-6 px-0 fu-deal__column"
    style="overflow: hidden"
  >
    <create-rent-modal
      :is-loading="isLoading"
      :update="updateRent"
      @isLoading="$emit('isLoading', $event)"
    ></create-rent-modal>
    <h5 class="text-capitalize fu-deal__column-title">Car rents</h5>
    <h6 class="mt-4">Confirmed</h6>
    <div class="px-3 fu-car-rent-wrapper">
      <p
        v-if="confirmedRents ? !confirmedRents.length : false"
        class="d-flex align-items-center justify-content-center pt-5 fu-deal__column-activity__empty"
      >
        There are no confirmed rents yet.
      </p>
      <b-row
        class="border-bottom p-1 d-flex align-items-baseline"
        v-for="(rent, index) in confirmedRents"
        :key="`${rent.rentId}-${index}`"
      >
        <div>{{ rent.firstName }} {{ rent.lastName }}</div>

        <div class="ml-4">
          {{ dateFormatter(rent.from) }} - {{ dateFormatter(rent.to) }}
        </div>
        <div>
          <button
            class="col-lg-1 col-1 px-0 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
            @click="deleteRent(rent.rentId)"
          >
            <i class="far fa-trash-alt ml-lg-3 ml-0 row-icon"></i>
          </button>
        </div>
        <div>
          <button
            class="col-lg-1 col-1 px-0 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
            @click="openUpdateRentModal(rent)"
          >
            <i class="far fa-eye ml-lg-3 ml-0 row-icon"></i>
          </button>
        </div>

        <div>
          <button
            class="col-lg-1 col-1 px-0 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
            @click="toggleConfirmedRent(rent)"
          >
            <i class="far fa-times-circle ml-lg-3 ml-0 row-icon"></i>
          </button>
        </div>
      </b-row>
    </div>
    <h6 class="mt-1">Reserved</h6>
    <div class="px-3 fu-car-rent-wrapper">
      <p
        v-if="reservedRents ? !reservedRents.length : false"
        class="d-flex align-items-center justify-content-center pt-5 fu-deal__column-activity__empty"
      >
        There are no reserved rents yet.
      </p>
      <b-row
        class="border-bottom p-1 d-flex align-items-baseline"
        v-for="(rent, index) in reservedRents"
        :key="`${rent.rentId}-${index}`"
      >
        <div>{{ rent.firstName }} {{ rent.lastName }}</div>
        <div class="ml-4">
          {{ dateFormatter(rent.from) }} - {{ dateFormatter(rent.to) }}
        </div>
        <div>
          <button
            class="col-lg-1 col-1 px-0 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
            @click="deleteRent(rent.rentId)"
          >
            <i class="far fa-trash-alt ml-lg-3 ml-0 row-icon"></i>
          </button>
        </div>
        <div>
          <button
            class="col-lg-1 col-1 px-0 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
            @click="openUpdateRentModal(rent)"
          >
            <i class="far fa-eye ml-lg-3 ml-0 row-icon"></i>
          </button>
        </div>
        <div>
          <button
            class="col-lg-1 col-1 px-0 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
            @click="toggleConfirmedRent(rent)"
          >
            <i class="far fa-check-circle ml-lg-3 ml-0 row-icon"></i>
          </button>
        </div>
      </b-row>
    </div>
    <div class="mx-0 mt-2 px-0">
      <button
        class="col-3 fu-deal__column-activity__button"
        :disabled="isLoading"
        @click="openNewRentModal"
      >
        Add a rent
      </button>
    </div>
  </main>
</template>

<script>
import CreateRentModal from "@/components/cars/car/rent/CreateRentModal";

export default {
  name: "CarRents",
  components: { CreateRentModal },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedRent: { clientId: null, from: new Date(), to: new Date() },
      updateRent: false,
    };
  },
  computed: {
    rents() {
      return this.$store.state.car.carData.rents;
    },
    reservedRents() {
      return this.rents.filter((e) => {
        return !e.isConfirmed;
      });
    },
    confirmedRents() {
      return this.rents.filter((e) => {
        return e.isConfirmed;
      });
    },
  },
  methods: {
    dateFormatter(date) {
      return `${new Date(date).toLocaleDateString()}`;
    },
    formatServices(rent) {
      return rent.services.map((el) => {
        return el.id;
      });
    },
    async toggleConfirmedRent(rent) {
      try {
        this.isLoading = true;
        rent.isConfirmed = !rent.isConfirmed;
        rent.services = this.formatServices(rent);
        const res = await this.$axios.put(
          `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/rent/` +
            rent.rentId,
          rent
        );

        await this.$store.commit("car/UPDATE_RENT", res.data);
        this.$bvToast.toast("Car rent updated", {
          title: `Success`,
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast("Car rent failed ", {
          title: `Failed`,
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },

    openNewRentModal() {
      this.selectedRent = { clientId: null, from: new Date(), to: new Date() };
      this.updateRent = false;
      this.$nextTick(() => {
        this.$bvModal.show("createRentModal");
      });
    },
    openUpdateRentModal(rent) {
      this.$store.commit("car/SET_RENT_DATA", rent);
      this.updateRent = true;
      this.$nextTick(() => {
        this.$bvModal.show("createRentModal");
      });
    },
    async deleteRent(id) {
      try {
        this.isLoading = true;

        await this.$axios.delete(
          `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/rent/${id}`
        );

        await this.$store.commit("car/DELETE_RENT", id);
        this.$bvToast.toast("Car rent deleted", {
          title: `Success`,
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast("Car rent task failed to delete", {
          title: `Failed`,
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.row-icon {
  position: relative;
}
</style>
