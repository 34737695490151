var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap col-12 p-0 mt-5"},[_c('b-table',{attrs:{"table-class":"fu-table","bordered":"","hover":"","sticky-header":"55vh","sort-compare-options":{ numeric: true, ignorePunctuation: true },"sort-by":_vm.sortBy,"sort-desc":_vm.sortOrder,"items":_vm.users,"fields":_vm.userTableComputed,"per-page":_vm.itemsPerPage},on:{"sort-changed":_vm.sortChanged,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortOrder=$event},"update:sort-desc":function($event){_vm.sortOrder=$event}},scopedSlots:_vm._u([{key:"head(select)",fn:function(){return [_c('b-form-checkbox',{attrs:{"checked":_vm.allSelectedOnPage},on:{"change":_vm.selectAll}})]},proxy:true},{key:"cell(firstName)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}},{key:"cell(select)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"checked":_vm.selectedUsersId.includes(item.id)},on:{"change":function($event){return _vm.selectUser(item.id)}}})]}},{key:"cell(permissions)",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"text-capitalize mb-0",class:{ 'w-100 text-left': !_vm.userHasPermission }},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-center w-100"},[_c('auth-action',{attrs:{"permission-name":"users.write"}},[_c('button',{staticClass:"fu-button__transparent px-0 mr-3",on:{"click":function($event){return _vm.editUser(item.id)}}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"far fa-edit fu-search-bar__status-tooltip fu-table__tooltip",attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Edit"}})])]),_c('auth-action',{attrs:{"permission-name":"users.delete"}},[_c('button',{staticClass:"fu-button__transparent px-0",on:{"click":function($event){return _vm.deleteUser(item)}}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"far fa-trash-alt fu-search-bar__status-tooltip fu-table__tooltip",attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Delete"}})])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }