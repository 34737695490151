<template>
  <div class="d-flex align-items-center justify-content-between w-100 py-4">
    <documents-modal
      @addDocument="addDocument"
      @removeDocument="removeDocument"
      :documents="documents"
      type="car"
    ></documents-modal>

    <div class="d-flex align-items-center">
      <button
        class="fu-button__transparent px-0"
        :disabled="isLoading"
        @click="discardChanges"
      >
        <i
          class="far fa-arrow-alt-circle-left mr-4 fu-navigation__arrow-back"
        ></i>
      </button>
      <h3 class="mb-0">{{ carTitle }}</h3>
    </div>
    <div class="d-flex justify-content-end align-items-center fu-deal__footer">
      <button
        class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
        @click="showDocuments"
        :disabled="isLoading"
      >
        <span><i :class="`far fa-file mr-2`"></i>Documents</span>
      </button>
      <button
        class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
        @click="saveChanges"
        :disabled="isLoading"
      >
        <span><i :class="`far fa-edit mr-2`"></i>Save</span>
      </button>

      <button
        class="btn btn-primary p-3"
        @click="discardChanges"
        :disabled="isLoading"
      >
        <span><i class="far fa-times mr-2"></i>Cancel</span>
      </button>
    </div>
  </div>
</template>

<script>
import DocumentsModal from "../../shared/DocumentsModal";

export default {
  name: "CarDetailsHeader",
  components: { DocumentsModal },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    brand() {
      return this.$store.state.car.carData.brand.name;
    },
    model() {
      return this.$store.state.car.carData.model;
    },
    carTitle() {
      return `View car ${this.brand} - ${this.model}`;
    },
    documents() {
      return this.$store.state.car.carData.documents;
    },
  },

  methods: {
    removeDocument(index) {
      this.documents.splice(index, 1);
    },
    showDocuments() {
      this.$bvModal.show("documentsModal");
    },
    addDocument(document) {
      this.documents.push(document);
      this.$emit("saveChanges");
    },
    async saveChanges() {
      this.$emit("saveChanges");
    },
    async discardChanges() {
      const conf = confirm("Do you really want discard all changes?");
      if (conf) await this.$router.go(-1);
    },
  },
};
</script>
