<template>
  <div class="d-flex flex-wrap col-12 p-0 mt-0 mt-lg-4 fu-search-bar">
    <div
      class="d-flex flex-wrap justify-content-between align-items-center col-12 p-0 mt-3"
    >
      <div
        :class="{
          visible: isVisible,
          invisible: !isVisible,
        }"
        class="d-flex align-items-center order-lg-0 order-1 col-lg-4 col-md-12 px-0 mt-lg-0 mt-5 btn-group fu-search-bar__status"
        role="group"
        aria-label="Basic example"
      >
        <button
          id="kt-button-status-active"
          :class="{ active: status === 'Active' }"
          @click="status = 'Active'"
        >
          <span><i class="far fa-check-circle mr-2"></i>Active</span>
        </button>
        <button
          id="kt-button-status-archived"
          :class="{ active: status === 'Archived' }"
          @click="status = 'Archived'"
        >
          <span><i class="far fa-archive mr-2"></i>Archived</span>
        </button>
        <i
          v-b-tooltip.hover
          data-toggle="tooltip"
          data-placement="top"
          :title="title"
          class="far fa-info-circle ml-3 fu-search-bar__status-tooltip"
        ></i>
      </div>

      <div
        class="d-flex flex-wrap align-items-center justify-content-end order-lg-1 order-0 col-lg-8 col-12 px-0 mt-lg-0 mt-md-4"
      >
        <div
          class="col-lg-4 col-12 pl-0 fu-search-bar__search-wrapper"
          @click="handleClick"
        >
          <i class="far fa-search ml-3"></i>
          <input
            id="kt-input-search"
            ref="searchInput"
            v-model="search"
            type="text"
            name="search"
            class="px-0 pl-3 mr-3 search"
            placeholder="Search"
          />
        </div>

        <div
          class="d-flex flex-wrap align-items-stretch justify-content-between justify-content-lg-end pl-0 pl-lg-3 pr-0 mt-3 mt-lg-0 actionButtons"
        >
          <auth-action :permission-name="deletePermissionName" class="mr-3">
            <fu-button
              :id="multipleDeleteButtonId"
              :title="multipleDeleteButtonName"
              :disabled="selectedItems.length === 0 || isLoading"
              @click="$emit('deleteSelectedItem', selectedItems)"
            >
              <template #button-icon
                ><i class="far fa-trash-alt mr-2 mb-1"></i
              ></template>
            </fu-button>
          </auth-action>

          <auth-action :permission-name="permissionName">
            <fu-button
              id="createUserButton"
              :title="buttonTitle"
              class="fu-search-bar__create-button"
              @click="$emit('createNew')"
            >
              <template #button-icon><i class="far fa-plus mr-2"></i></template>
            </fu-button>
          </auth-action>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  name: "SearchBar",
  props: {
    title: {
      type: String,
      required: true,
    },
    multipleDeleteButtonId: {
      type: String,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    permissionName: {
      type: String,
      required: true,
    },
    deletePermissionName: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    status: {
      get() {
        return this.$store.state.status;
      },
      set(value) {
        this.$store.commit("CHANGE_STATUS", value);
      },
    },
    search: {
      get() {
        return this.$store.state.search;
      },
      set(value) {
        this.$store.commit("CHANGE_SEARCH_STRING", value);
      },
    },
    multipleDeleteButtonName() {
      return `Delete selected ${
        this.selectedItems.length ? `(${this.selectedItems.length})` : ""
      }`;
    },
    isVisible() {
      return this.$route.name !== "ClientsDashboard";
    },
  },
  watch: {
    search(value) {
      this.emitSearch(value);
    },
    status() {
      this.$emit("changeStatus");
    },
  },
  methods: {
    emitSearch: lodash.debounce(function (value) {
      this.$emit("searchChanged", encodeURIComponent(value));
    }, 500),

    handleClick() {
      this.$refs.searchInput.focus();
    },
  },
};
</script>

<style>
@media only screen and (max-width: 900px) {
  .actionButtons {
    width: 100%;
  }
}
</style>
