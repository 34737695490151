<template>
  <b-sidebar
    id="createClientSidebar"
    ref="createClientSidebar"
    right
    backdrop
    sidebar-class="fu-sidebar fu-sidebar__client"
    header-class="fu-sidebar__header"
    body-class="fu-sidebar__body flex-wrap align-items-start"
    footer-class="fu-sidebar__footer"
    @hidden="closeSideBar"
  >
    <template #header>
      <span v-if="update">Update Client</span>
      <span v-else>Create Client</span>
      <i class="far fa-times fa-1x" @click="closeSideBar"></i>
    </template>

    <validation-observer ref="createClientForm" class="col-12 mt-3 px-0">
      <create-client-form :is-loading="isLoading"></create-client-form>
    </validation-observer>

    <template #footer>
      <div class="d-flex justify-content-end align-items-center col-12">
        <button
          v-if="update"
          id="kt-button-client-submit-update"
          class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
          @click="updateClient"
          :disabled="isLoading"
        >
          <span><i class="far fa-plus mr-2"></i>Update</span>
        </button>
        <button
          v-else
          id="kt-button-client-submit-create"
          class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
          @click="createClient"
          :disabled="isLoading"
        >
          <span><i class="far fa-plus mr-2"></i>Create</span>
        </button>
        <button
          id="kt-button-client-submit-close"
          class="btn btn-primary p-3"
          @click="closeSideBar"
          :disabled="isLoading"
        >
          <span><i class="far fa-times mr-2"></i>Cancel</span>
        </button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import CreateClientForm from "@/components/clients/client/CreateClientForm";
export default {
  name: "CreateClientSidebar",
  components: { CreateClientForm },
  data() {
    return {
      isLoading: false,
      searchValue: "",
      selectedClient: {},
    };
  },
  computed: {
    update() {
      return !!this.$store.getters["client/getClientFieldData"]("id");
    },
  },
  methods: {
    closeSideBar() {
      this.$refs.createClientSidebar.hide();
      this.$store.commit("client/RESET_CLIENT_DATA");
      this.selectedClient = {};
      this.$refs.createClientForm.reset();
    },
    errorToast(message) {
      this.$bvToast.toast(message, {
        title: "Create client failed",
        variant: "danger",
        solid: true,
      });
    },
    updateClient() {
      this.$refs.createClientForm.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          try {
            const clientData = this.$store.state.client.clientData;

            await this.$axios.put(
              `${process.env.VUE_APP_API_URL}/api/clients/` + clientData.id,
              clientData
            );
            this.$emit("refreshClients");
            this.$bvToast.toast("Success", {
              title: `Client was successfully updated`,
              variant: "success",
              solid: true,
            });
            this.closeSideBar();
          } catch (error) {
            console.error(error);
            if (error.response.data.errors) {
              let err = error.response.data.errors;
              for (const key in err) {
                this.errorToast(err[key][0]);
              }
            } else {
              this.errorToast(error.response.data.message);
            }
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    createClient() {
      this.$refs.createClientForm.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          try {
            const clientData = this.$store.state.client.clientData;

            await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/clients`,
              clientData
            );
            this.$emit("refreshClients");
            this.$bvToast.toast("Success", {
              title: `Client was successfully created`,
              variant: "success",
              solid: true,
            });
            this.closeSideBar();
          } catch (error) {
            console.error(error);
            if (error.response.data.errors) {
              let err = error.response.data.errors;
              for (const key in err) {
                this.errorToast(err[key][0]);
              }
            } else {
              this.errorToast(error.response.data.message);
            }
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
