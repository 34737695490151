<template>
  <div class="d-flex flex-column align-items-center mb-3">
    <div
      v-if="showLogo"
      class="d-flex align-items-center justify-content-center fu-login__logo-wrapper"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginHeader",
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
