<template>
  <main class="d-flex flex-wrap mb-5 mt-3 h-100">
    <b-overlay :show="fetching" class="d-flex flex-wrap col-12">
      <b-row class="w-100">
        <b-col class="col-6">
          <b-overlay :show="filteringInc">
            <filter-board
              title="Incomes"
              type-code="income"
              @filtering="filteringInc = $event"
            />
          </b-overlay>
        </b-col>
        <b-col class="col-6">
          <b-overlay :show="filteringExp">
            <filter-board
              title="Expenses"
              type-code="expense"
              @filtering="filteringExp = $event"
            />
          </b-overlay>
        </b-col>
      </b-row>

      <b-row class="w-100 border-top pt-2">
        <b-col class="col-6">
          <b-row>
            <b-col class="d-flex flex-wrap align-items-center">
              <div class="d-flex flex-wrap justify-content-between w-100">
                <h3>Our fleet</h3>

                <fu-button
                  id="car-clear"
                  title="Clear"
                  is-small
                  variant="white"
                  :is-loading="isLoading"
                  @click="clearFilter('cars')"
                />
              </div>
              <multiselect
                v-model="carId"
                :options="brands"
                :loading="isLoading"
                placeholder="Brand"
                class="multiselect mt-2 col-5 pl-0"
                :custom-label="customCarLabel"
                @search-change="searchCar = $event"
              >
                <template slot="placeholder">Brand </template>

                <template slot="option" slot-scope="props">
                  <div
                    class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
                  >
                    <span
                      >{{ props.option.brand.name }} {{ props.option.model }}
                      <i class="far fa-long-arrow-alt-right"></i>
                      {{ props.option.registrationNumber }}</span
                    >
                  </div>
                </template>

                <template slot="noResult">
                  <div class="d-flex flex-wrap align-items-center">
                    <span>No car found: "{{ searchCar }}" </span>
                  </div>
                </template>
              </multiselect>

              <multiselect
                v-model="carClientId"
                :options="clients"
                :loading="isLoading"
                placeholder="Client"
                class="multiselect mt-2 col-5 px-0 pr-3"
                :custom-label="customClientsLabel"
                @search-change="searchCarClientId = $event"
              >
                <template slot="placeholder">Clients </template>

                <template slot="option" slot-scope="props">
                  <div
                    class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
                  >
                    <span
                      >{{ props.option.firstName }}
                      {{ props.option.lastName }}</span
                    >
                  </div>
                </template>

                <template slot="noResult">
                  <div class="d-flex flex-wrap align-items-center">
                    <span>No car found: "{{ searchCarClientId }}" </span>
                  </div>
                </template>
              </multiselect>

              <div class="col-3 px-0 pr-3">
                <date-picker
                  v-model="carFrom"
                  mode="date"
                  :model-config="datePickerConfig"
                  :disabled="isLoading"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="mt-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker multiselect date-picker-border"
                      :value="inputValue"
                      placeholder="Date from"
                      v-on="inputEvents"
                    />
                  </template>
                </date-picker>
              </div>

              <div class="col-3 px-0 pr-3">
                <date-picker
                  v-model="carTo"
                  mode="date"
                  :model-config="datePickerConfig"
                  :disabled="isLoading"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="mt-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker multiselect date-picker-border"
                      :value="inputValue"
                      placeholder="Date to"
                      v-on="inputEvents"
                    />
                  </template>
                </date-picker>
              </div>
            </b-col>
          </b-row>
          <filter-response
            :response="carResponse"
            :total="totalCarInc"
            :total-exp="totalCarExp"
            :total-difference="totalCar"
            no-border="no-border"
          />
        </b-col>

        <b-col class="col-6 border-left">
          <b-row>
            <b-col class="d-flex flex-wrap align-items-center">
              <div class="d-flex flex-wrap justify-content-between w-100">
                <h3>Clients</h3>

                <fu-button
                  id="clear-clients"
                  title="Clear"
                  is-small
                  variant="white"
                  :is-loading="isLoading"
                  @click="clearFilter('clients')"
                />
              </div>
              <multiselect
                v-model="client"
                :options="clients"
                :loading="isLoading"
                placeholder="Client"
                class="multiselect mt-2 col-5 px-0 pr-3"
                :custom-label="customClientsLabel"
                @search-change="searchClient = $event"
              >
                <template slot="placeholder">Clients </template>

                <template slot="option" slot-scope="props">
                  <div
                    class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
                  >
                    <span
                      >{{ props.option.firstName }}
                      {{ props.option.lastName }}</span
                    >
                  </div>
                </template>

                <template slot="noResult">
                  <div class="d-flex flex-wrap align-items-center">
                    <span>No client found: "{{ searchClient }}" </span>
                  </div>
                </template>
              </multiselect>

              <multiselect
                v-model="clientCarId"
                :options="brands"
                :loading="isLoading"
                placeholder="Brand"
                class="multiselect mt-2 col-5 px-0 pl-3"
                :custom-label="customCarLabel"
                @search-change="searchCarClient = $event"
              >
                <template slot="placeholder">Brand </template>

                <template slot="option" slot-scope="props">
                  <div
                    class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
                  >
                    <span
                      >{{ props.option.brand.name }} {{ props.option.model }}
                      <i class="far fa-long-arrow-alt-right"></i>
                      {{ props.option.registrationNumber }}</span
                    >
                  </div>
                </template>

                <template slot="noResult">
                  <div class="d-flex flex-wrap align-items-center">
                    <span>No car found: "{{ searchCarClient }}" </span>
                  </div>
                </template>
              </multiselect>

              <div class="col-3 px-0 pr-3">
                <date-picker
                  v-model="clientFrom"
                  mode="date"
                  :model-config="datePickerConfig"
                  :disabled="isLoading"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="mt-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker multiselect date-picker-border"
                      :value="inputValue"
                      placeholder="Date from"
                      v-on="inputEvents"
                    />
                  </template>
                </date-picker>
              </div>

              <div class="col-3 px-0 pl-3">
                <date-picker
                  v-model="clientTo"
                  mode="date"
                  :model-config="datePickerConfig"
                  :disabled="isLoading"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="mt-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker multiselect date-picker-border"
                      :value="inputValue"
                      placeholder="Date to"
                      v-on="inputEvents"
                    />
                  </template>
                </date-picker>
              </div>
            </b-col>
          </b-row>

          <filter-response
            :response="clientResponse"
            :total="totalClientInc"
            :total-exp="totalClientExp"
            :total-difference="totalClient"
            no-border="no-border"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </main>
</template>

<script>
import { mapState } from "vuex";
import lodash from "lodash";
import FilterBoard from "@/components/analytics/FilterBoard.vue";
import FilterResponse from "@/components/analytics/FilterResponse.vue";
import {DatePicker} from "v-calendar";

export default {
  name: "AnalyticsDashboard",
  components: { FilterResponse, FilterBoard, DatePicker },
  data() {
    return {
      datePickerConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        format: "YYYY-MM-DD",
      },
      filteringExp: false,
      filteringInc: false,
      fetching: false,
      searchCar: "",
      searchCarClient: "",
      searchClient: "",
      searchCarClientId: "",
      isLoading: false,
      carResponse: null,
      clientResponse: null,

      // Total results
      totalClientInc: {},
      totalClientExp: {},
      totalCarInc: {},
      totalCarExp: {},

      // Dates
      carFrom: null,
      carTo: null,
      clientFrom: null,
      clientTo: null,

      totalClient: {},
      totalCar: {},
    };
  },
  computed: {
    ...mapState({
      perPage: (state) => state.perPage,
      incomeFilters: (state) => state.analytics.incomeFilters,
      expenseFilters: (state) => state.analytics.expenseFilters,
      brands: (state) => state.analytics.filterCars,
      clients: (state) => state.analytics.filterClients,
    }),
    carId: {
      get() {
        const selectedId = this.$store.getters["analytics/getAnalyticsData"](
          "carId"
        );
        return this.brands.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("analytics/CHANGE_ANALYTICS_FIELD", {
          fieldName: "carId",
          fieldValue: fieldValue ? fieldValue.id : null,
        });
      },
    },
    clientCarId: {
      get() {
        const selectedId = this.$store.getters["analytics/getAnalyticsData"](
          "clientCarId"
        );
        return this.brands.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("analytics/CHANGE_ANALYTICS_FIELD", {
          fieldName: "clientCarId",
          fieldValue: fieldValue ? fieldValue.id : null,
        });
      },
    },
    carClientId: {
      get() {
        const selectedId = this.$store.getters["analytics/getAnalyticsData"](
          "carClientId"
        );
        return this.clients.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("analytics/CHANGE_ANALYTICS_FIELD", {
          fieldName: "carClientId",
          fieldValue: fieldValue ? fieldValue.id : null,
        });
      },
    },
    client: {
      get() {
        const selectedId = this.$store.getters["analytics/getAnalyticsData"](
          "clientId"
        );
        return this.clients.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("analytics/CHANGE_ANALYTICS_FIELD", {
          fieldName: "clientId",
          fieldValue: fieldValue ? fieldValue.id : null,
        });
      },
    },
  },
  created() {
    this.fetchAnalytics();
  },
  watch: {
    searchCar(value) {
      this.searchCars(value);
    },
    searchCarClient(value) {
      this.searchCarsClient(value);
    },
    searchClient(value) {
      this.searchClients(value);
    },
    searchCarClientId(value) {
      this.searchCarClientsId(value);
    },
    carId(value) {
      if (value) this.fetchCarAnalytics();
    },
    client(value) {
      if (value) this.fetchClientAnalytics();
    },
    clientCarId() {
      this.fetchClientAnalytics();
    },
    carClientId() {
      this.fetchCarAnalytics();
    },
    carTo() {
      this.fetchCarAnalytics();
    },
    carFrom() {
      this.fetchCarAnalytics();
    },
    clientTo() {
      this.fetchClientAnalytics();
    },
    clientFrom() {
      this.fetchClientAnalytics();
    },
  },
  methods: {
    clearFilter(segment) {
      if (segment === "clients") {
        this.client = null;
        this.clientCarId = null;
        this.clientFrom = null;
        this.clientTo = null;
        this.$set(
          this,
          "clientResponse",
          this.setTotals([], "totalClientExp", "totalClientInc", "totalClient")
        );
        this.totalClientInc = {};
        this.totalClientExp = {};
        this.totalClient = {};
      } else if (segment === "cars") {
        this.carId = null;
        this.carFrom = null;
        this.carClientId = null;
        this.carTo = null;
        this.$set(
          this,
          "carResponse",
          this.setTotals([], "totalCarExp", "totalCarInc", "totalCar")
        );
        this.totalCarInc = {};
        this.totalCarExp = {};
        this.totalCar = {};
      }
    },
    customCarLabel({ brand, model, registrationNumber }) {
      return `${brand.name} ${model} - ${registrationNumber}`;
    },
    customClientsLabel({ firstName, lastName }) {
      return `${firstName} ${lastName}`;
    },
    searchCars: lodash.debounce(async function (value) {
      try {
        this.isLoading = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars?page=1&perPage=${
            this.perPage
          }&isDisabled=0&search=${value ? value : ""}${
            this.sortBy ? `&sortBy=${this.sortBy}` : ""
          }&sortOrder=${this.sortOrder ? "desc" : "asc"}`
        );
        this.$store.commit("analytics/SET_ANALYTICS_CARS", res.data.data);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }, 500),
    searchCarsClient: lodash.debounce(async function (value) {
      try {
        this.isLoading = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars?page=1&perPage=${
            this.perPage
          }&isDisabled=0&search=${value ? value : ""}${
            this.sortBy ? `&sortBy=${this.sortBy}` : ""
          }&sortOrder=${this.sortOrder ? "desc" : "asc"}`
        );
        this.$store.commit("analytics/SET_ANALYTICS_CARS", res.data.data);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }, 500),
    searchCarClientsId: lodash.debounce(async function (value) {
      try {
        this.isLoading = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/clients?page=1&perPage=${
            this.perPage
          }&isDisabled=0&search=${value ? value : ""}${
            this.sortBy ? `&sortBy=${this.sortBy}` : ""
          }&sortOrder=${this.sortOrder ? "desc" : "asc"}`
        );
        this.$store.commit("analytics/SET_ANALYTICS_CLIENTS", res.data.data);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }, 500),
    searchClients: lodash.debounce(async function (value) {
      try {
        this.isLoading = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/clients?page=1&perPage=${
            this.perPage
          }&isDisabled=0&search=${value ? value : ""}${
            this.sortBy ? `&sortBy=${this.sortBy}` : ""
          }&sortOrder=${this.sortOrder ? "desc" : "asc"}`
        );
        this.$store.commit("analytics/SET_ANALYTICS_CLIENTS", res.data.data);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }, 500),
    formatDate(date) {
      return new Date(date).toISOString().split("T")[0];
    },
    setTotals(data, exp, inc, total) {
      let arr = [];
      if (data && data.length > 0) {
        arr = data.filter((el) => {
          if (el.label === "Total expense") {
            this[exp] = el;
            return false;
          }
          if (el.label === "Total income") {
            this[inc] = el;
            return false;
          }
          if (el.label === "Total") {
            this[total] = el;
            return false;
          }
          return true;
        });
      }
      return arr;
    },
    async fetchCarAnalytics() {
      try {
        if (!this.carId) return;
        this.isLoading = true;

        this.totalCarInc = {};
        this.totalCarExp = {};
        this.totalCar = {};

        let endpoint = "";
        if (this.carClientId) endpoint += `clientId=${this.carClientId.id}&`;
        if (this.carFrom)
          endpoint += `fromDate=${this.formatDate(this.carFrom)}&`;
        if (this.carTo) endpoint += `toDate=${this.formatDate(this.carTo)}&`;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/analytics?carId=${this.carId.id}&type=ourFleet&${endpoint}`
        );

        this.$set(
          this,
          "carResponse",
          this.setTotals(res.data, "totalCarExp", "totalCarInc", "totalCar")
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchClientAnalytics() {
      try {
        if (!this.client) return;
        this.isLoading = true;
        let endpoint = "";

        this.totalClientInc = {};
        this.totalClientExp = {};
        this.totalClient = {};

        if (this.clientCarId) endpoint += `carId=${this.clientCarId.id}&`;
        if (this.clientFrom)
          endpoint += `fromDate=${this.formatDate(this.clientFrom)}&`;
        if (this.clientTo)
          endpoint += `toDate=${this.formatDate(this.clientTo)}&`;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/analytics?clientId=${this.client.id}&type=clients&${endpoint}`
        );
        this.$set(
          this,
          "clientResponse",
          this.setTotals(
            res.data,
            "totalClientExp",
            "totalClientInc",
            "totalClient"
          )
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchAnalytics() {
      try {
        this.fetching = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/analytics/meta`
        );
        this.$store.commit("analytics/SET_ANALYTIC_META", res.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Analytics fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/analytics.scss";
</style>
