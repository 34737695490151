<template>
  <div class="d-flex flex-wrap">
    <div class="w-100 invoice-header">
      <span v-if="!isEdit">{{ index + 1 }}. Income/Expense</span>
      <button v-if="index !== 0 && !isEdit" @click="$emit('deleteRow')">
        Delete <i class="far fa-trash-alt ml-1"></i>
      </button>
    </div>

    <div v-if="isAutogenerated" class="w-100 mb-2 autogenerated-text">
      <span>* Autogenerated expense</span>
    </div>
    <validation-provider
      tag="label"
      :name="`category-${id}`"
      rules="required"
      v-slot="{ errors }"
      class="col-6 px-0"
    >
      <span class="w-100 font-weight-bold">Category*</span><br />

      <multiselect
        v-model="category"
        :options="categories"
        :loading="isLoading"
        :disabled="isLoading || isEdit"
        label="name"
        track-by="code"
        placeholder="Select category"
        class="multiselect mt-2"
        :class="{ multiselect__disabled: isEdit }"
        :multiple="false"
        :searchable="false"
        :close-on-select="true"
        @select="chooseSegments"
        @remove="removeSegment"
      >
        <template slot="option" slot-scope="props">
          <div
            class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
          >
            <span>{{ props.option.name }}</span>
          </div>
        </template>
      </multiselect>
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>

    <validation-provider
      tag="label"
      rules="required"
      :name="`segment-${id}`"
      v-slot="{ errors }"
      class="col-6 px-1"
    >
      <span class="w-100 font-weight-bold">Segment*</span>
      <br />
      <multiselect
        v-model="segment"
        :options="segments"
        :loading="isLoading"
        :disabled="
          isLoading ||
          isEdit ||
          (category && category.length === 0) ||
          !category
        "
        :class="{ multiselect__disabled: isEdit }"
        placeholder="Select segment"
        label="name"
        track-by="code"
        class="multiselect mt-2"
        :multiple="false"
        :searchable="false"
        :close-on-select="true"
        @select="changeSegment"
        @remove="changeSegment"
      >
        <template slot="option" slot-scope="props">
          <div
            class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
          >
            <span>{{ props.option.name }}</span>
          </div>
        </template>
      </multiselect>
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>

    <div class="d-flex flex-wrap pt-2">
      <validation-provider
        tag="label"
        rules="required"
        :name="`cost-${id}`"
        v-slot="{ errors }"
        class="col-4 px-1"
      >
        <span class="w-100 font-weight-bold">Cost*</span>
        <br />
        <input
          v-model="cost"
          type="text"
          name="deposit"
          required
          placeholder="Invoice cost"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        rules="required"
        :name="`invoice-number-${id}`"
        v-slot="{ errors }"
        class="col-4 px-1"
      >
        <span class="w-100 font-weight-bold">Invoice number*</span>
        <br />
        <input
          v-model="invoiceNumber"
          type="text"
          name="deposit"
          required
          placeholder="Invoice number"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        rules="required"
        :name="`date-${id}`"
        v-slot="{ errors }"
        class="col-4 px-1"
      >
        <span class="w-100 font-weight-bold d-block mb-2">Invoice date*</span>
        <date-picker
          v-model="date"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <validation-provider
      tag="label"
      :name="`description-${id}`"
      v-slot="{ errors }"
      class="w-100 px-0 mt-2"
    >
      <span class="w-100 font-weight-bold">Description</span><br />
      <input
        v-model="description"
        type="text"
        name="description"
        placeholder="Description"
        class="fu-login__input"
        :disabled="isLoading"
      />
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {DatePicker} from "v-calendar";

export default {
  name: "CarInvoiceRow",
  components: { DatePicker },
  props: {
    id: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      segments: [],
      datePickerConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        format: "YYYY-MM-DD",
      },
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.car.invoiceData.categories,
      expenseSegments: (state) => state.car.invoiceData.expenseSegments,
      incomeSegments: (state) => state.car.invoiceData.incomeSegments,
      isEdit: (state) => state.car.invoiceData.editModal,
    }),
    category: {
      get() {
        return this.$store.getters["car/getCarInvoiceData"](
          this.index,
          "category"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_INVOICE_FIELD", {
          fieldName: "category",
          fieldValue,
          id: this.index,
        });
      },
    },
    segment: {
      get() {
        return this.$store.getters["car/getCarInvoiceData"](
          this.index,
          "segment"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_INVOICE_FIELD", {
          fieldName: "segment",
          fieldValue,
          id: this.index,
        });
      },
    },
    cost: {
      get() {
        return this.$store.getters["car/getCarInvoiceData"](this.index, "cost");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_INVOICE_FIELD", {
          fieldName: "cost",
          fieldValue,
          id: this.index,
        });
      },
    },
    date: {
      get() {
        return this.$store.getters["car/getCarInvoiceData"](
          this.index,
          "billedOnDate"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_INVOICE_FIELD", {
          fieldName: "billedOnDate",
          fieldValue,
          id: this.index,
        });
      },
    },
    invoiceNumber: {
      get() {
        return this.$store.getters["car/getCarInvoiceData"](
          this.index,
          "invoiceNumber"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_INVOICE_FIELD", {
          fieldName: "invoiceNumber",
          fieldValue,
          id: this.index,
        });
      },
    },
    description: {
      get() {
        return this.$store.getters["car/getCarInvoiceData"](
          this.index,
          "description"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_INVOICE_FIELD", {
          fieldName: "description",
          fieldValue,
          id: this.index,
        });
      },
    },
    isAutogenerated: {
      get() {
        return this.$store.getters["car/getCarInvoiceData"](
          this.index,
          "isAutogenerated"
        );
      },
    },
  },
  methods: {
    changeSegment() {
      this.$store.commit("car/RESET_EXPENSE_FIELDS", this.index);
    },
    removeSegment() {
      this.segments = [];
      this.chooseSegments();
    },
    chooseSegments() {
      this.$nextTick(() => {
        this.segments =
          this.category.code === "expense"
            ? this.expenseSegments
            : this.incomeSegments;
        this.segment = "";
      });
    },
  },
};
</script>
