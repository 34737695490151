<template>
  <div ref="dealDetailsWrapper" class="col-lg-3 col-6 px-0 fu-deal__column">
    <h5 class="text-capitalize fu-deal__column-title">Client Folders</h5>

    <folders
      class="mt-4"
      :only-read-client="onlyReadClient"
      type="client"
    ></folders>
  </div>
</template>

<script>
import Folders from "@/components/shared/Folders";
export default {
  name: "ClientFolders",
  props: {
    onlyReadClient: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Folders,
  },
};
</script>

<style scoped></style>
