<template>
  <main>
    <b-overlay :show="isFetching" class="d-flex flex-wrap col-12">
      <client-details-header
        :only-read-client="onlyReadClient"
        @saveChanges="saveChanges"
        :is-loading="isLoading"
      ></client-details-header>
      <validation-observer
        ref="clientDetailsDashboardForm"
        class="d-flex flex-wrap justify-content-between col-12 px-0"
      >
        <client-details
          :only-read-client="onlyReadClient"
          :is-loading="false"
        ></client-details>
        <client-folders :only-read-client="onlyReadClient"></client-folders>
        <client-rents></client-rents>
      </validation-observer>
    </b-overlay>
  </main>
</template>

<script>
import ClientDetails from "@/components/clients/client/ClientDetails";
import ClientRents from "@/components/clients/client/ClientRents";
import ClientDetailsHeader from "@/components/clients/details/ClientDetailsHeader";
import ClientFolders from "@/components/clients/client/ClientFolders";

export default {
  name: "ClientExtended",
  components: {
    ClientDetails,
    ClientRents,
    ClientDetailsHeader,
    ClientFolders,
  },
  data() {
    return {
      isFetching: false,
      isLoading: false,
    };
  },
  computed: {
    onlyReadClient() {
      return (
        this.$store.getters["userPermissions"].includes("clients.has") &&
        !this.$store.getters["userPermissions"].includes("clients.write")
      );
    },
  },
  beforeMount() {
    this.fetchSelectedClient();
  },
  methods: {
    async fetchSelectedClient() {
      try {
        this.isLoading = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/clients/${this.$route.params.id}`
        );
        this.$store.commit("client/SET_CLIENT_DATA", res.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Client fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async saveChanges() {
      this.$refs.clientDetailsDashboardForm.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;

            const res = await this.$axios.put(
              `${process.env.VUE_APP_API_URL}/api/clients/${this.$route.params.id}`,
              this.$store.state.client.clientData
            );
            await this.$store.commit("client/SET_CLIENT_DATA", res.data);
            this.$bvToast.toast("Client was updated", {
              title: `Success`,
              variant: "success",
              solid: true,
            });
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Updating client details failed", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>
