<template>
  <b-modal
    id="createRentModal"
    ref="createRentModal"
    centered
    content-class="fu-modal"
    header-class="fu-modal__header fu-modal__submit-header"
    body-class="fu-modal__body"
    footer-class="fu-modal__footer"
    @hidden="closeModal"
  >
    <div class="col-12">
      <validation-observer ref="carRentActivityForm" :disabled="true">
        <div class="row justify-content-between">
          <div v-if="!viewOnly" class="col-6 px-1">
            <span class="w-100 font-weight-bold">Client*</span>

            <select
              v-model="rentData.clientId"
              type="text"
              class="col-12 py-1 fu-login__input fu-select h-50"
              :disabled="isLoading || viewOnly"
            >
              <option :key="null" :value="null">Select client</option>
              <option
                v-for="option in clients"
                :key="option.id"
                :value="option.id"
              >
                {{ option.firstName + " " + option.lastName }}
              </option>
            </select>
          </div>
          <div v-else class="col-6 px-1">
            <router-link
              :to="{ name: 'CarExtended', params: { id: rentData.carId } }"
              class="d-flex align-items-center w-100"
              target="_blank"
              style="text-decoration: none !important"
            >
              <span class="font-weight-bold">Car</span>
              <i class="far fa-link pl-1" style="font-size: 15px"></i>
            </router-link>
            <input
              id="kt-input-car-name"
              v-model="rentData.carName"
              type="text"
              name="deposit"
              required
              placeholder="deposit"
              class="fu-login__input"
              disabled
            />
          </div>
          <validation-provider
            tag="label"
            rules="required"
            v-slot="{ errors }"
            class="col-6 px-1"
          >
            <span class="w-100 font-weight-bold">Deposit*</span>
            <br />
            <input
              id="kt-input-car-vin-number"
              v-model="rentData.deposit"
              type="text"
              name="deposit"
              required
              placeholder="deposit"
              class="fu-login__input"
              :disabled="isLoading || viewOnly"
            />
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="row justify-content-between">
          <validation-provider
            tag="label"
            rules="required"
            v-slot="{ errors }"
            class="col-6 px-1"
          >
            <span class="w-100 font-weight-bold">Instalment without tax*</span>
            <br />
            <input
              id="kt-input-car-instalment-without-tax"
              v-model="rentData.instalmentWithoutTax"
              type="text"
              name="instalmentWithoutTax"
              required
              placeholder="Instalment without tax"
              class="fu-login__input"
              :disabled="isLoading || viewOnly"
            />
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider
            tag="label"
            v-slot="{ errors }"
            class="col-6 px-1"
          >
            <span class="w-100 font-weight-bold">Mileage limit</span>
            <br />
            <input
              id="kt-input-car-mileage-limit"
              v-model="rentData.mileageLimit"
              type="text"
              name="mileageLimit"
              placeholder="Mileage limit"
              class="fu-login__input"
              :disabled="isLoading || viewOnly"
            />
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider
            tag="div"
            name="service"
            class="col-12 px-0 pl-3 mb-3 row"
            id="kt-service-div"
          >
            <label class="col-6 px-0 font-weight-bold">Services </label>
            <multiselect
              v-model="rentData.services"
              :options="services"
              :loading="isLoading"
              :custom-label="customServicesLabel"
              track-by="name"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Services"
              class="multiselect"
              :disabled="isLoading || viewOnly"
              :class="{ multiselect__disabled: isLoading || viewOnly }"
            >
              <template slot="placeholder"> Services </template>

              <template slot="option" slot-scope="props">
                <div
                  class="d-flex flex-wrap align-items-center justify-content-between kt-service-select-item"
                >
                  <span>{{ props.option.name }}</span>
                </div>
              </template>

              <template slot="noResult">
                <div class="d-flex flex-wrap align-items-center">
                  <span>No service found </span>
                </div>
              </template>
            </multiselect>
          </validation-provider>
        </div>

        <div class="row justify-content-between">
          <validation-provider
            tag="label"
            name="From date"
            rules="required"
            v-slot="{ errors }"
            class="col-6 ml-0 pl-1"
          >
            <span class="w-100 font-weight-bold">Start date*</span>
            <date-picker
              v-if="!viewOnly"
              v-model="rentData.from"
              mode="date"
              :model-config="datePickerConfig"
              :disabled="isLoading || viewOnly"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
            <div v-else>
              {{ dateFormatter(rentData.from) }}
            </div>

            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider
            tag="label"
            name="To date"
            rules="required"
            v-slot="{ errors }"
            class="col-6 ml-0 pl-1"
          >
            <span class="w-100 font-weight-bold">End date*</span>
            <date-picker
              v-if="!viewOnly"
              v-model="rentData.to"
              mode="date"
              :model-config="datePickerConfig"
              :disabled="isLoading || viewOnly"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
            <div v-else>
              {{ dateFormatter(rentData.to) }}
            </div>
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </validation-observer>

      <div v-if="rentData.documents && rentData.documents.length > 0">
        <h5>Documents:</h5>
        <div v-for="(document, index) in rentData.documents" :key="document.id">
          <span>
            <a :href="getDocumentUrl(document.url)" target="_blank">{{
              document.filename
            }}</a>
            <button
              class="col-lg-1 col-1 px-0 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
              @click="removeDocument(index)"
            >
              <i class="far fa-trash-alt ml-lg-3 ml-0"></i>
            </button>
          </span>
        </div>
      </div>
    </div>

    <template #modal-header>
      <div>
        <h3 v-if="update">Update a rent</h3>
        <h3 v-else-if="viewOnly">View a rent</h3>
        <h3 v-else>Create a rent</h3>
      </div>
    </template>
    <template #modal-footer>
      <fu-button
        v-if="update && !viewOnly"
        @click="uploadDocuments"
        title="Upload documents"
        :disabled="isLoading"
        id="uploadDocumentsButton"
      >
      </fu-button>
      <fu-button
        v-if="update && !viewOnly"
        @click="updateRent"
        title="Update"
        :disabled="isLoading"
        id="updateRentButton"
      >
      </fu-button>
      <fu-button
        v-else-if="!viewOnly"
        @click="addNewRent"
        title="Create"
        :disabled="isLoading"
        id="addNewRentButton"
      >
      </fu-button>
    </template>
    <document-upload-modal
      :rent-id="rentData.rentId"
      @addDocument="addDocument"
    ></document-upload-modal>
  </b-modal>
</template>

<script>
import {DatePicker} from "v-calendar";
import DocumentUploadModal from "@/components/cars/car/rent/DocumentUploadModal";

export default {
  name: "CreateRentModal",
  components: { DatePicker, DocumentUploadModal },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    update: {
      type: Boolean,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datePickerConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        format: "YYYY-MM-DD",
      },
    };
  },
  computed: {
    clients() {
      return this.$store.state.cars.carsMeta.clients;
    },
    rentData() {
      return this.$store.state.car.rentData;
    },
    services() {
      return this.$store.state.cars.carsMeta.services;
    },
  },
  methods: {
    customServicesLabel({ name }) {
      return `${name}, `;
    },
    dateFormatter(date) {
      return `${new Date(date).toLocaleDateString()}`;
    },
    getDocumentUrl(url) {
      return process.env.VUE_APP_AWS_BUCKET_URL + url;
    },
    removeDocument(index) {
      this.rentData.documents.splice(index, 1);
    },
    addDocument(value) {
      this.rentData.documents.push(value);
    },
    uploadDocuments() {
      this.$bvModal.show("documentUploadModal");
    },
    resetData() {
      this.$store.commit("car/RESET_RENT_DATA");
    },
    closeModal() {
      this.resetData();
      this.$refs.createRentModal.hide();
    },
    formatServices() {
      return this.rentData.services.map((el) => {
        return el.id;
      });
    },
    async addNewRent() {
      this.$refs.carRentActivityForm.validate().then(async (success) => {
        if (success) {
          try {
            this.$emit("isLoading", true);
            this.rentData.to = new Date(this.rentData.to)
              .toISOString()
              .split("T")[0];
            this.rentData.from = new Date(this.rentData.from)
              .toISOString()
              .split("T")[0];

            this.rentData["services"] = this.formatServices();
            this.rentData.instalmentWithoutTax = this.rentData.instalmentWithoutTax.replace(
              ".",
              ""
            );
            this.rentData.instalmentWithoutTax = this.rentData.instalmentWithoutTax.replace(
              ",",
              "."
            );
            this.rentData.deposit = this.rentData.deposit.replace(".", "");
            this.rentData.deposit = this.rentData.deposit.replace(",", ".");

            const res = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/rent`,
              this.rentData
            );

            await this.$store.commit("car/ADD_RENT", res.data);
            this.$bvToast.toast("Car rent added", {
              title: `Success`,
              variant: "success",
              solid: true,
            });

            this.resetData();

            this.$refs.carRentActivityForm.reset();
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Car rent failed ", {
              title: `Failed`,
              variant: "danger",
              solid: true,
            });
          } finally {
            this.$emit("isLoading", false);
          }
        }
      });
    },
    async updateRent() {
      this.$refs.carRentActivityForm.validate().then(async (success) => {
        if (success) {
          try {
            this.$emit("isLoading", true);
            this.rentData.to = new Date(this.rentData.to)
              .toISOString()
              .split("T")[0];
            this.rentData.from = new Date(this.rentData.from)
              .toISOString()
              .split("T")[0];

            this.rentData["services"] = this.formatServices();

            const res = await this.$axios.put(
              `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/rent/` +
                this.rentData.rentId,
              this.rentData
            );

            await this.$store.commit("car/UPDATE_RENT", res.data);
            this.$bvToast.toast("Car rent updated", {
              title: `Success`,
              variant: "success",
              solid: true,
            });

            this.$refs.carRentActivityForm.reset();
            this.closeModal();
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Car rent failed ", {
              title: `Failed`,
              variant: "danger",
              solid: true,
            });
          } finally {
            this.$emit("isLoading", false);
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>
