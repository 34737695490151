import lodash from "lodash";
import Vue from "vue";

const state = {
  unassignedDeals: [],
  companiesWithoutLeadBoards: [],
  leadForwardsData: {
    leadForwards: null,
    companies: null,
  },
  dealsByCreator: [],
  dealsByLeadBoard: [],
  submittedQuestionnairesByUser: [],
  meta: {
    brands: [],
    categories: [],
    segments: [],
  },
  incomeFilters: [],
  expenseFilters: [],
  carId: null,
  clientCarId: null,
  carClientId: null,
  clientId: null,
  filterCars: [],
  filterClients: [],
  carResults: null,
  clientResults: null,
};
const mutations = {
  SET_DEALS_BY_CREATOR(state, items) {
    state.dealsByCreator = lodash.cloneDeep(items);
  },
  SET_DEALS_BY_LEAD_BOARD(state, items) {
    state.dealsByLeadBoard = lodash.cloneDeep(items);
  },
  SET_SUBMITTED_QUESTIONNAIRE_BY_USER(state, items) {
    state.submittedQuestionnairesByUser = lodash.cloneDeep(items);
  },
  SET_ANALYTIC_META(state, meta) {
    state.meta = meta;
  },
  CHANGE_ANALYTICS_FIELD(state, { fieldName, fieldValue }) {
    state[fieldName] = fieldValue;
  },
  SET_ANALYTICS_CARS(state, cars) {
    Vue.set(state, "filterCars", cars);
  },
  SET_ANALYTICS_CLIENTS(state, clients) {
    Vue.set(state, "filterClients", clients);
  },
};

const actions = {};

const getters = {
  getAnalyticsData: (state) => (fieldName) => {
    return state[fieldName];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
