<template>
  <main>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        name="first name"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">First name*</span><br />
        <input
          id="kt-input-client-first-name"
          v-model="firstName"
          type="text"
          name="first name"
          required
          placeholder="First name"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Last name *</span>
        <br />
        <input
          id="kt-input-client-last-name"
          v-model="lastName"
          type="text"
          name="last name"
          required
          placeholder="Last name"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        rules="required|email|max:70"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Email *</span>
        <br />
        <input
          id="kt-input-client-email"
          v-model="email"
          type="email"
          name="email"
          required
          placeholder="Email"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        name="phone"
        :rules="{ max: 30, regex: /^[+]?[0-9]*$/ }"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Phone number*</span>
        <br />
        <input
          id="kt-input-client-phone"
          v-model="phone"
          type="text"
          name="phone"
          placeholder="Phone number"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        name="address"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Address*</span>
        <br />
        <input
          id="kt-input-client-address"
          v-model="address"
          type="text"
          name="address"
          placeholder="Address"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        name="Driving license number"
        rules=""
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Driving license number</span>
        <br />
        <input
          id="kt-input-client-driving-license-number"
          v-model="drivingLicenseNumber"
          type="text"
          name="driving license number"
          placeholder="driving license number"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        name="tax number"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Tax number*</span>
        <br />
        <input
          id="kt-input-client-tax-number"
          v-model="taxNumber"
          type="text"
          name="tax number"
          placeholder="Tax number"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        name="discount"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Discount (%)*</span>
        <br />
        <input
          id="kt-input-client-discount"
          v-model="discount"
          type="text"
          name="discount"
          placeholder="Discount"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        name="Identification number"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Identification number</span>
        <br />
        <input
          id="kt-input-client-id-number"
          v-model="idNumber"
          type="text"
          name="identification number"
          placeholder="Identification number"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        name="Identification document number"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold"
          >Identification document number</span
        >
        <br />
        <input
          id="kt-input-client-id-document-number"
          v-model="idDocumentNumber"
          type="text"
          name="identification document number"
          placeholder="Identification document number"
          class="fu-login__input"
          :disabled="isLoading || onlyReadClient"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
      <span v-if="!onlyReadClient" class="w-100 font-weight-bold mt-5"
        >Client users</span
      >

      <validation-provider
        v-if="!onlyReadClient"
        tag="div"
        :name="`Users`"
        v-slot="{ errors }"
        class="col-12 px-0 pr-0"
      >
        <multiselect
          v-model="clientUsers"
          :options="allUsers"
          :loading="isLoading"
          :custom-label="userFullName"
          track-by="id"
          :multiple="true"
          class="multiselect kt-div-input"
          :class="{ multiselect__disabled: isLoading }"
          :disabled="isLoading"
          @search-change="searchValue = $event"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
        >
          <template slot="placeholder"> User</template>
          <template slot="option" slot-scope="props">
            <div class="d-flex justify-content-between">
              <span class="kt-select-item">{{
                `${props.option.firstName} ${props.option.lastName}`
              }}</span>
              <span v-if="isSelectedUser(props.option)"
                ><i class="fas fa-times"></i
              ></span>
            </div>
          </template>
          <template slot="selection" slot-scope="{ values }">
            <div v-if="values.length">
              <span
                v-for="(value, index) in values"
                class="multiselect__single mx-1 multiselect__multi"
                :key="value.id"
                >{{ value.firstName + " " + value.lastName }}
                <span v-if="index < values.length - 1">,</span>
              </span>
            </div>
          </template>
          <template slot="noResult">
            <span>No position found: "{{ searchValue }}" </span>
          </template>
        </multiselect>
        <span class="text-danger">{{ errors[0] }}</span>
      </validation-provider>
    </div>
  </main>
</template>

<script>
export default {
  name: "CreateClientForm",
  data() {
    return {
      searchValue: "",
      users: [],
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    onlyReadClient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allUsers() {
      return this.$store.state.clients.clientsMeta.users;
    },
    clientUsers: {
      get() {
        return this.$store.getters["client/getClientFieldData"]("users");
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "users",
          fieldValue,
        });
      },
    },
    firstName: {
      get() {
        return this.$store.getters["client/getClientFieldData"]("firstName");
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "firstName",
          fieldValue,
        });
      },
    },
    lastName: {
      get() {
        return this.$store.getters["client/getClientFieldData"]("lastName");
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "lastName",
          fieldValue,
        });
      },
    },
    email: {
      get() {
        return this.$store.getters["client/getClientFieldData"]("email");
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "email",
          fieldValue,
        });
      },
    },
    phone: {
      get() {
        return this.$store.getters["client/getClientFieldData"]("phone");
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "phone",
          fieldValue,
        });
      },
    },
    address: {
      get() {
        return this.$store.getters["client/getClientFieldData"]("address");
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "address",
          fieldValue,
        });
      },
    },
    idNumber: {
      get() {
        return this.$store.getters["client/getClientFieldData"]("idNumber");
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "idNumber",
          fieldValue,
        });
      },
    },
    idDocumentNumber: {
      get() {
        return this.$store.getters["client/getClientFieldData"](
          "idDocumentNumber"
        );
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "idDocumentNumber",
          fieldValue,
        });
      },
    },
    taxNumber: {
      get() {
        return this.$store.getters["client/getClientFieldData"]("taxNumber");
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "taxNumber",
          fieldValue,
        });
      },
    },
    discount: {
      get() {
        return this.$store.getters["client/getClientFieldData"]("discount");
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "discount",
          fieldValue,
        });
      },
    },
    drivingLicenseNumber: {
      get() {
        return this.$store.getters["client/getClientFieldData"](
          "drivingLicenseNumber"
        );
      },
      set(fieldValue) {
        this.$store.commit("client/CHANGE_CLIENT_FIELD", {
          fieldName: "drivingLicenseNumber",
          fieldValue,
        });
      },
    },
  },
  beforeMount() {
    this.fetchClientsMeta();
  },
  methods: {
    isSelectedUser(user) {
      return this.clientUsers.findIndex((el) => el.id === user.id) > -1;
    },
    userFullName({ firstName, lastName }) {
      return `${firstName} ${lastName ? lastName : ""}`;
    },
    async fetchClientsMeta() {
      try {
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/clients/meta`
        );
        this.$store.commit("clients/SET_CLIENTS_META", res.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Clients meta fetching failed",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>

<style scoped></style>
