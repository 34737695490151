<template>
  <div
    class="col-12 px-0 mb-3 mt-4 pt-3 fu-deal__column-wrapper-activity fu-border__lightgrey-top"
  >
    <div
      ref="carCommentsWrapper"
      class="col-12 px-0 mb-3 fu-deal__column-activity"
    >
      <h6 class="text-capitalize font-weight-bold fu-deal__column-title">
        INCOME & EXPENSES
        <span class="d-flex flex-wrap total-invoices w-100 pt-1">
          <span
            v-for="(value, index) in totalMeta"
            :key="`total-meta-${index}`"
          >
            <span
              v-if="value.label !== 'Total'"
              class="mx-1"
              :class="[value.categoryCode === 'income' ? 'green' : 'red']"
              ><i
                class="far mr-1"
                :class="[
                  `fa-file-${
                    value['categoryCode'] === 'expense'
                      ? 'export expense'
                      : 'import income'
                  }`,
                ]"
              ></i>
              {{ value.data }}€,
            </span>
            <span
              v-else
              class="mx-1"
              :class="[value.categoryCode === 'income' ? 'green' : 'red']"
              ><span style="color: black" class="mr-1">Total:</span>
              {{ value.data }}€</span
            >
          </span>
        </span>
      </h6>
      <p
        v-if="invoices && invoices.length === 0"
        class="d-flex align-items-center justify-content-center pt-5 fu-deal__column-activity__empty"
      >
        There are no incomes/expenses on the list
      </p>

      <table v-else class="mt-5 w-100 invoice-table">
        <tr>
          <th>#</th>
          <th>Segment</th>
          <th>Date</th>
          <th>Cost (€)</th>
          <th>Description</th>
          <th></th>
          <th></th>
        </tr>

        <tr
          v-for="(value, index) in invoices"
          :key="index"
          class="text-left col-12 p-0 pl-0"
        >
          <td>
            <i
              v-if="value.isAutogenerated"
              class="far fa-exclamation-circle pr-2 autogenerated"
              v-b-tooltip.hover.bottom
              title="Autogenerated expense"
            />

            <i
              class="far mr-1"
              :class="[
                `fa-file-${
                  value.category.code === 'expense'
                    ? 'export expense'
                    : 'import income'
                }`,
              ]"
            ></i>
            {{ value.invoiceNumber }}
          </td>
          <td>{{ value.segment.name }}</td>
          <td>{{ dateFormatter(value.billedOnDate) }}</td>
          <td>{{ value.cost }}</td>
          <td>{{ value.description }}</td>
          <td>
            <button
              class="p-0 button-transparent edit-car-invoice"
              :disabled="isLoading"
              @click="editCarFinancial(value.id)"
            >
              <i class="far fa-edit ml-0 row-icon"></i>
            </button>
          </td>
          <td>
            <button
              class="p-0 button-transparent delete-car-invoice"
              :disabled="isLoading"
              @click="deleteCarFinancial(value.id)"
            >
              <i class="far fa-trash-alt ml-0 row-icon"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>

    <car-invoice-modal
      :is-loading="isLoading"
      @isLoading="isLoading = $event"
    />

    <div
      class="d-flex flex-wrap justify-content-between align-items-start col-12 px-0"
    >
      <button
        class="col-3 px-0 fu-deal__column-activity__button"
        :disabled="isLoading || initialFetch"
        @click="openModal"
      >
        Add new
      </button>

      <b-pagination
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        next-class="next-page"
        prev-class="prev-page"
        last-class="last-page"
        first-class="first-page"
        size="sm"
        class="mb-0 mt-auto p-3 cars-invoice-pagination"
        col-5
        @input="fetchFinancials"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CarInvoiceModal from "@/components/cars/car/modal/CarInvoiceModal.vue";

export default {
  name: "CarActivityComments",
  components: { CarInvoiceModal },
  props: {
    initialFetch: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      newCommentText: null,
    };
  },
  computed: {
    ...mapState({
      perPage: (state) => state.perPage,
      totalMeta: (state) => state.car.invoiceData.total,
    }),
    invoices: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("invoices");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_DEAL_FIELD", {
          fieldName: "invoices",
          fieldValue,
        });
      },
    },
    currentPage: {
      get() {
        return this.$store.state.car.currentPage;
      },
      set(value) {
        this.$store.commit("car/CHANGE_CURRENT_PAGE", value);
      },
    },
    total: {
      get() {
        return this.$store.state.car.total;
      },
      set(value) {
        this.$store.commit("car/CHANGE_TOTAL", value);
      },
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show("carInvoiceModal");
    },
    async editCarFinancial(id) {
      await this.$store.commit("car/FILL_CAR_INVOICE_EDIT_MODAL", id);
      this.$bvModal.show("carInvoiceModal");
    },
    dateFormatter(date) {
      if (!date) return "/";
      return `${new Date(date).toLocaleDateString()}`;
    },
    async deleteCarFinancial(id) {
      let conf = confirm(`Are you sure to delete income/expense ?`);
      if (conf) {
        try {
          this.isLoading = true;
          await this.$axios.delete(
            `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/${id}`
          );
          const total = await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/total`
          );
          this.$store.commit("car/DELETE_CAR_INVOICE", id);
          this.$store.commit("car/SET_CAR_INVOICE_TOTAL", total.data);

          this.$bvToast.toast("Item successfully deleted", {
            title: `Success`,
            variant: "success",
            solid: true,
          });
        } catch (e) {
          console.error(e);
          this.$bvToast.toast("Item failed to delete", {
            title: `Failed`,
            variant: "danger",
            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async fetchFinancials() {
      try {
        this.isLoading = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/?page=${this.currentPage}&perPage=${this.perPage}`
        );
        this.total = res.data.total;
        this.$store.commit("car/SET_INITIAL_INVOICES", res.data.data);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
