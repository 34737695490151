var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","name":"brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Brand*")]),_c('br'),_c('multiselect',{staticClass:"multiselect mt-2",attrs:{"options":_vm.brands,"loading":_vm.isLoading,"placeholder":"Brand","label":"name","track-by":"name"},on:{"search-change":function($event){_vm.searchValue = $event}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-between kt-select-item"},[_c('span',[_vm._v(_vm._s(props.option.name))]),_c('button',{staticClass:"multiselect__option-delete kt-button-remove-brand",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){$event.stopPropagation();return _vm.deleteBrand(props.option)}}},[_c('i',{staticClass:"far fa-trash-alt mr-2"})])])]}}],null,true),model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}},[_c('template',{slot:"placeholder"},[_vm._v("Brand ")]),_c('template',{slot:"noResult"},[_c('div',{staticClass:"d-flex flex-wrap align-items-center"},[_c('span',[_vm._v("No job brands found: \""+_vm._s(_vm.searchValue)+"\" ")]),_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"id":"kt-button-add-brand","disabled":_vm.isLoading},on:{"click":_vm.addNewBrand}},[_c('span',[_vm._v("Add new"),_c('i',{staticClass:"fal fa-plus text-white ml-3"})])])])])],2),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Model*")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-car-model","type":"text","name":"model","required":"","placeholder":"Model","disabled":_vm.isLoading},domProps:{"value":(_vm.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.model=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("VIN Number*")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vinNumber),expression:"vinNumber"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-car-vin-number","type":"text","name":"vinNumber","required":"","placeholder":"VIN number","disabled":_vm.isLoading},domProps:{"value":(_vm.vinNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.vinNumber=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Insurance number")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.insuranceNumber),expression:"insuranceNumber"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"insuranceNumber","placeholder":"Insurance number","disabled":_vm.isLoading},domProps:{"value":(_vm.insuranceNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.insuranceNumber=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex flex-wrap w-100"},[_c('validation-provider',{staticClass:"col-6 px-0 pr-1",attrs:{"tag":"label","name":"insurance-from-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold d-block mb-2"},[_vm._v("Insurance From")]),_c('date-picker',{attrs:{"mode":"date","model-config":_vm.datePickerConfig,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker",staticStyle:{"border-color":"#5a5c61 !important"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.insuranceFrom),callback:function ($$v) {_vm.insuranceFrom=$$v},expression:"insuranceFrom"}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"col-6 px-0 pl-1",attrs:{"tag":"label","name":"insurance-to-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold d-block mb-2"},[_vm._v("Insurance To")]),_c('date-picker',{attrs:{"mode":"date","model-config":_vm.datePickerConfig,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker",staticStyle:{"border-color":"#5a5c61 !important"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.insuranceTo),callback:function ($$v) {_vm.insuranceTo=$$v},expression:"insuranceTo"}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Leasing Price")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.leasingPrice),expression:"leasingPrice"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"leasingPrice","placeholder":"Leasing Price","disabled":_vm.isLoading},domProps:{"value":(_vm.leasingPrice)},on:{"input":function($event){if($event.target.composing){ return; }_vm.leasingPrice=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex flex-wrap w-100"},[_c('validation-provider',{staticClass:"col-6 px-0 pr-1",attrs:{"tag":"label","name":"leasing-from-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold d-block mb-2"},[_vm._v("Leasing From")]),_c('date-picker',{attrs:{"mode":"date","model-config":_vm.datePickerConfig,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker",staticStyle:{"border-color":"#5a5c61 !important"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.leasingFrom),callback:function ($$v) {_vm.leasingFrom=$$v},expression:"leasingFrom"}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"col-6 px-0 pl-1",attrs:{"tag":"label","name":"leasing-to-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold d-block mb-2"},[_vm._v("Leasing To")]),_c('date-picker',{attrs:{"mode":"date","model-config":_vm.datePickerConfig,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker",staticStyle:{"border-color":"#5a5c61 !important"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.leasingTo),callback:function ($$v) {_vm.leasingTo=$$v},expression:"leasingTo"}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Warranty number")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.warrantyNumber),expression:"warrantyNumber"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"warranty","placeholder":"Warranty number","disabled":_vm.isLoading},domProps:{"value":(_vm.warrantyNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.warrantyNumber=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex flex-wrap w-100"},[_c('validation-provider',{staticClass:"col-6 px-0 pr-1",attrs:{"tag":"label","name":"warranty-from-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold d-block mb-2"},[_vm._v("Warranty From")]),_c('date-picker',{attrs:{"mode":"date","model-config":_vm.datePickerConfig,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker",staticStyle:{"border-color":"#5a5c61 !important"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.warrantyFrom),callback:function ($$v) {_vm.warrantyFrom=$$v},expression:"warrantyFrom"}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"col-6 px-0 pl-1",attrs:{"tag":"label","name":"warranty-to-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold d-block mb-2"},[_vm._v("Warranty To")]),_c('date-picker',{attrs:{"mode":"date","model-config":_vm.datePickerConfig,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker",staticStyle:{"border-color":"#5a5c61 !important"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.warrantyTo),callback:function ($$v) {_vm.warrantyTo=$$v},expression:"warrantyTo"}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Vehicle owner*")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.owner),expression:"owner"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-owner","type":"text","name":"owner","required":"","placeholder":"Owner","disabled":_vm.isLoading},domProps:{"value":(_vm.owner)},on:{"input":function($event){if($event.target.composing){ return; }_vm.owner=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Registration number*")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registrationNumber),expression:"registrationNumber"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-car-registrationNumber","type":"text","name":"registrationNumber","required":"","placeholder":"registrationNumber","disabled":_vm.isLoading},domProps:{"value":(_vm.registrationNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.registrationNumber=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex flex-wrap w-100"},[_c('validation-provider',{staticClass:"col-6 px-0 pr-1",attrs:{"tag":"label","name":"registration-from-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold d-block mb-2"},[_vm._v("Registration From")]),_c('date-picker',{attrs:{"mode":"date","model-config":_vm.datePickerConfig,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker",staticStyle:{"border-color":"#5a5c61 !important"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.registrationFrom),callback:function ($$v) {_vm.registrationFrom=$$v},expression:"registrationFrom"}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"col-6 px-0 pl-1",attrs:{"tag":"label","name":"registration-to-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold d-block mb-2"},[_vm._v("Registration To")]),_c('date-picker',{attrs:{"mode":"date","model-config":_vm.datePickerConfig,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker",staticStyle:{"border-color":"#5a5c61 !important"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.registrationTo),callback:function ($$v) {_vm.registrationTo=$$v},expression:"registrationTo"}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-4"},[_c('span',{staticClass:"col-2 p-0 font-weight-bold"},[_vm._v("Archived: ")]),_c('b-form-checkbox',{staticClass:"ml-3 mb-3 fu-checkbox",attrs:{"id":"kt-checkbox-archived","name":"is-archived","size":"lg","switch":""},model:{value:(_vm.isDisabled),callback:function ($$v) {_vm.isDisabled=$$v},expression:"isDisabled"}}),_c('span',{staticClass:"mb-0 ml-5"},[_vm._v(_vm._s(_vm.isDisabled ? "Active" : "Disabled"))])],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Sold")]),_c('br'),_c('b-form-radio-group',{attrs:{"name":"radio-sub-component"},model:{value:(_vm.isSold),callback:function ($$v) {_vm.isSold=$$v},expression:"isSold"}},[_c('b-form-radio',{attrs:{"value":false}},[_vm._v("No")]),_c('b-form-radio',{attrs:{"value":true}},[_vm._v("Yes")])],1),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Types of transaction")]),_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-1","text-field":"name","name":"flavour-1"},model:{value:(_vm.transactionTypes),callback:function ($$v) {_vm.transactionTypes=$$v},expression:"transactionTypes"}},_vm._l((_vm.transactionTypesMeta),function(type){return _c('b-form-checkbox',{key:type.id,attrs:{"value":{ id: type.id, name: type.name }}},[_vm._v(_vm._s(type.name))])}),1),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }