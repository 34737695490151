<template>
  <div
    class="d-flex justify-content-between align-items-center col-12 fu-navigation"
  >
    <div class="d-flex align-items-center fu-navigation__bar">
      <auth-action permission-name="users.read">
        <router-link
          to="/"
          class="ml-4"
          :class="{ active: activeRouteElement('/') }"
          >Users</router-link
        >
      </auth-action>
      <!--      <auth-action permission-name="companies.read">-->
      <!--        <router-link-->
      <!--          to="/companies"-->
      <!--          class="ml-4"-->
      <!--          :class="{ active: activeRouteElement('/companies') }"-->
      <!--          >Companies</router-link-->
      <!--        >-->
      <!--      </auth-action>-->
      <auth-action permission-name="clients.read,clients.has">
        <router-link
          to="/clients"
          class="ml-4"
          :class="{ active: activeRouteElement('/clients') }"
          >Clients</router-link
        >
      </auth-action>
      <auth-action permission-name="cars.read">
        <router-link
          to="/our-fleet"
          class="ml-4"
          :class="{ active: activeRouteElement('/our-fleet') }"
          >Our fleet</router-link
        >
      </auth-action>

      <!--      <auth-action-->
      <!--        permission-name="lead_board.read,lead_board.member,lead_forward.read"-->
      <!--      >-->
      <!--        <router-link-->
      <!--          to="/lead-board"-->
      <!--          class="ml-4"-->
      <!--          :class="{-->
      <!--            active: activeRouteElement(-->
      <!--              `/lead-board${$route.params.id ? `/${$route.params.id}` : ''}`-->
      <!--            ),-->
      <!--          }"-->
      <!--          >Lead boards</router-link-->
      <!--        >-->
      <!--      </auth-action>-->
      <span>
        <router-link
          to="/analytics"
          class="ml-4"
          :class="{ active: activeRouteElement('/analytics') }"
          >Analytics</router-link
        >
      </span>
    </div>

    <div class="fu-navigation__user">
      <b-dropdown
        id="kt-button-logout"
        :text="getUserFullName"
        class="m-md-2"
        menu-class="p-0"
      >
        <b-dropdown-item link-class="p-0 fu-navigation__user-dropdown">
          <button @click="userLogout">
            <i class="fas fa-sign-out-alt mr-3"></i>Logout
          </button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  data() {
    return {
      dropdownContent: false,
    };
  },
  computed: {
    getUserFullName() {
      return this.$store.state.loggedUser
        ? `${this.$store.state.loggedUser.firstName} ${this.$store.state.loggedUser.lastName}`
        : "- -";
    },
  },
  methods: {
    activeRouteElement(path) {
      return this.$route.path === path;
    },
    async userLogout() {
      try {
        await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/auth/logout`
        );
        await this.$store.dispatch("resetUserData");
        await this.$store.dispatch("users/resetUsersData");
        this.$store.commit("companies/SET_COMPANIES", []);
        await this.$router.push({ name: "Login" });
      } catch (error) {
        this.$bvToast.toast(error.response.data.message, {
          title: "Logout failed",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>
