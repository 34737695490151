<template>
  <main ref="clientsDashboard" class="d-flex flex-wrap mb-5 h-100">
    <b-overlay :show="fetching" class="d-flex flex-wrap col-12">
      <search-bar
        title="Create new clients"
        button-title="Create client"
        :selected-items="selectedClients"
        :is-loading="isLoading"
        multiple-delete-button-id="kt-button-delete-selected-client"
        permissionName="clients.write"
        delete-permission-name="clients.delete"
        @createNew="isNewClient"
        @changeStatus="fetchClients"
        @searchChanged="searchChanged($event)"
        @deleteSelectedItem="deleteSelectedClients($event)"
      >
      </search-bar>

      <clients-table
        ref="clientsTable"
        @sortingChanged="fetchClients"
        @selectedClients="selectClient($event)"
        @deleteClients="deleteSelectedClients($event)"
        @onlyReadClient="onlyReadClient = $event"
      ></clients-table>
    </b-overlay>

    <create-client-sidebar
      @refreshClients="fetchClients"
    ></create-client-sidebar>

    <div class="d-flex align-items-end flex-column col-12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        next-class="next-page"
        prev-class="prev-page"
        last-class="last-page"
        first-class="first-page"
        size="lg"
        class="mb-0 mt-auto p-3"
        col-12
        @input="fetchClients"
      ></b-pagination>
    </div>
    <!-- END OF PAGINATION -->
  </main>
</template>

<script>
import SearchBar from "@/components/gadgets/SearchBar";
import ClientsTable from "@/components/clients/ClientsTable";
import CreateClientSidebar from "@/components/clients/client/CreateClientSidebar";
export default {
  name: "ClientsDashboard",
  components: {
    CreateClientSidebar,
    ClientsTable,
    SearchBar,
  },
  data() {
    return {
      selectedClients: [],
      onlyReadClient: false,
      fetching: false,
      isLoading: false,
    };
  },
  created() {
    this.fetchClients();
  },
  computed: {
    currentPage: {
      get() {
        return this.$route.query.page || 1;
      },
      set(fieldValue) {
        this.$router.push({
          name: "ClientsDashboard",
          query: { page: fieldValue },
        });
      },
    },

    status() {
      return this.$store.state.status;
    },
    totalRows() {
      return this.$store.state.clients.totalRows;
    },
    perPage() {
      return this.$store.state.perPage;
    },
    globalSearch() {
      return this.$store.state.search;
    },
    sortBy() {
      return this.$store.state.sortBy;
    },
    sortOrder() {
      return this.$store.state.sortOrder;
    },
  },
  methods: {
    isNewClient() {
      this.$store.commit("client/RESET_CLIENT_DATA");
      this.$root.$emit("bv::toggle::collapse", "createClientSidebar");
    },
    searchChanged() {
      this.currentPage = 1;
      this.fetchClients();
    },
    selectClient(event) {
      this.$set(this, "selectedClients", event);
    },
    async fetchClients() {
      try {
        this.fetching = true;

        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/clients?page=${
            this.currentPage
          }&perPage=${this.perPage}${
            this.globalSearch ? `&search=${this.globalSearch}` : ""
          }${this.sortBy ? `&sortBy=${this.sortBy}` : ""}&sortOrder=${
            this.sortOrder ? "desc" : "asc"
          }`
        );
        this.$store.commit("clients/SET_TOTAL_ROWS", res.data.total);
        this.$store.commit("clients/SET_CLIENTS", res.data.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Clients fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.fetching = false;
        this.$refs.clientsTable
          ? (this.$refs.clientsTable.$children[0].$el.scrollTop = 0)
          : null;
      }
    },
    async deleteSelectedClients(client) {
      const checkIfArray = Array.isArray(client);
      let conf = confirm(
        `Are you sure to delete ${
          checkIfArray
            ? `multiple clients (${client.length})`
            : `client: ${client.email}`
        }`
      );
      if (conf) {
        this.isLoading = true;
        try {
          await this.deleteRequest(checkIfArray, client);

          this.$bvToast.toast("Success", {
            title: "Delete was successful",
            variant: "success",
            solid: true,
          });
          await this.fetchClients();
          this.$set(this, "selectedClients", []);
          this.$set(this.$refs.clientsTable, "selectedClientsId", []);
        } catch (error) {
          console.error(error);
          this.$bvToast.toast(error.response.data.message, {
            title: "Client delete failed",
            variant: "danger",
            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async deleteRequest(checkIfArray, client) {
      if (!checkIfArray) {
        return await this.$axios.delete(
          `${process.env.VUE_APP_API_URL}/api/clients/${client.id}`
        );
      } else {
        return await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/clients/bulk-delete`,
          { clients: client }
        );
      }
    },
  },
};
</script>
