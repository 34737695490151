import Vue from "vue";
import lodash from "lodash";

const state = {
  totalRows: 0,
  cars: [],
  carsMeta: {
    brands: [],
    users: [],
    clients: [],
    transactionTypes: [],
    services: [],
  },
};

const mutations = {
  SET_CARS(state, cars) {
    Vue.set(state, "cars", cars);
  },
  SET_CARS_META(state, carsMeta) {
    Vue.set(state, "carsMeta", carsMeta);
  },
  SET_TOTAL_ROWS(state, totalPaginationRows) {
    Vue.set(state, "totalRows", totalPaginationRows);
  },
  SET_BRANDS_DATA(state, brands) {
    state.carsMeta.brands = lodash.cloneDeep(
      brands.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }
        return 0;
      })
    );
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
