<template>
  <div class="d-flex flex-wrap col-12 p-0 mt-5">
    <b-table
      table-class="fu-table"
      bordered
      hover
      sticky-header="55vh"
      :sort-compare-options="{ numeric: true, ignorePunctuation: true }"
      @sort-changed="sortChanged"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortOrder"
      :items="clients"
      :fields="fields"
      :per-page="itemsPerPage"
    >
      <template #head(select)>
        <b-form-checkbox
          v-model="allSelectedOnPage"
          @change="selectAll"
        ></b-form-checkbox>
      </template>

      <template #cell(select)="{ item }">
        <b-form-checkbox
          :checked="selectedClientsId.includes(item.id)"
          @change="selectClient(item.id)"
        ></b-form-checkbox>
      </template>

      <template #cell(actions)="{ item }">
        <div
          class="d-flex flex-wrap align-items-center justify-content-center w-100"
        >
          <auth-action permission-name="clients.read,clients.has">
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="editClient(item.id, true)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                class="far fa-eye fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>

          <auth-action permission-name="clients.write">
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="editClient(item.id, false)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
                class="far fa-edit fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>
          <auth-action permission-name="clients.delete">
            <button
              class="fu-button__transparent px-0"
              @click="deleteClients(item)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
                class="far fa-trash-alt fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import AuthAction from "@/components/gadgets/AuthAction";
export default {
  name: "ClientsTable",
  components: { AuthAction },
  data() {
    return {
      allSelectedOnPage: false,
      fields: [
        {
          key: "select",
          sortable: false,
          label: "",
        },
        {
          key: "firstName",
          sortable: true,
        },
        {
          key: "lastName",
          sortable: true,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "phone",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
          label: "",
        },
      ],
      selectedClientsId: [],
    };
  },
  watch: {
    clients() {
      this.checkIfAllSelected();
    },
    selectedClientsId(value) {
      this.$emit("selectedClients", value);
      this.checkIfAllSelected();
    },
  },
  computed: {
    clients() {
      return this.$store.state.clients.clients;
    },
    itemsPerPage() {
      return this.$store.state.perPage;
    },
    sortBy: {
      get() {
        return this.$store.state.sortBy;
      },
      set(fieldValue) {
        this.$store.commit("CHANGE_SORT_PARAMETER", {
          fieldName: "sortBy",
          fieldValue,
        });
      },
    },
    sortOrder: {
      get() {
        return this.$store.state.sortOrder;
      },
      set(fieldValue) {
        this.$store.commit("CHANGE_SORT_PARAMETER", {
          fieldName: "sortOrder",
          fieldValue,
        });
      },
    },
  },
  methods: {
    async sortChanged() {
      this.$nextTick(() => {
        this.$emit("sortingChanged");
      });
    },
    checkIfAllSelected() {
      const clientsIds = this.clients.map(({ id }) => id);
      let count = 0;
      clientsIds.forEach((clientId) => {
        if (this.selectedClientsId.includes(clientId)) count++;
      });

      this.allSelectedOnPage =
        clientsIds.length === count && clientsIds.length !== 0;
    },
    selectClient(clientId) {
      if (!this.selectedClientsId.includes(clientId)) {
        this.selectedClientsId.push(clientId);
      } else {
        const index = this.selectedClientsId.indexOf(clientId);
        if (index > -1) this.selectedClientsId.splice(index, 1);
      }
    },
    selectAll(value) {
      this.clients.forEach((client) => {
        if (value) {
          if (!this.selectedClientsId.includes(client.id)) {
            this.selectedClientsId.push(client.id);
          }
        } else {
          const index = this.selectedClientsId.indexOf(client.id);
          if (index > -1) this.selectedClientsId.splice(index, 1);
        }
      });
    },
    async editClient(selectedId, onlyRead) {
      const client = this.clients.find(({ id }) => id === selectedId);
      this.$store.commit("client/SET_CLIENT_DATA", client);
      this.$emit("onlyReadClient", onlyRead);
      await this.$router.push({
        name: "ClientExtended",
        params: { id: selectedId },
      });
    },

    deleteClients(selectedClient) {
      this.$emit("deleteClients", selectedClient);
    },
  },
};
</script>
