import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/login/Login.vue";
import SetPassword from "@/views/login/SetPassword";
import ForgotPassword from "@/views/login/ForgotPassword";
import UsersDashboard from "@/views/UsersDashboard";
import NoPermissions from "@/views/NoPermissions";
import CarExtended from "@/views/CarExtended";
import {
  checkUserPermissions,
  setCsrfCookie,
  setUserData,
} from "@/helpers/auth";
import AnalyticsDashboard from "@/views/AnalyticsDashboard";
import ClientsDashboard from "@/views/ClientsDashboard";
import CarsDashboard from "@/views/CarsDashboard";
import ClientExtended from "@/views/login/ClientExtended";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: UsersDashboard,
    meta: { permissionName: "users" },
  },

  {
    path: "/analytics",
    name: "Analytics",
    component: AnalyticsDashboard,
    meta: { permissionName: "analytics" },
  },
  // {
  //   path: "/companies",
  //   component: CompaniesDashboard,
  //   meta: { permissionName: "companies" },
  // },
  {
    path: "/clients",
    component: ClientsDashboard,
    name: "ClientsDashboard",
    meta: { permissionName: "clients" },
  },
  {
    path: "/clients/:id",
    name: "ClientExtended",
    component: ClientExtended,
    meta: { permissionName: "clients" },
  },
  {
    path: "/our-fleet",
    component: CarsDashboard,
    name: "CarsDashboard",
    meta: { permissionName: "cars" },
  },
  {
    path: "/our-fleet/:id",
    name: "CarExtended",
    component: CarExtended,
    meta: { permissionName: "cars" },
  },
  // {
  //   path: "/lead-board",
  //   name: "LeadBoardDashboard",
  //   component: LeadsDashboard,
  //   meta: { permissionName: "lead" },
  // },
  // {
  //   path: "/lead-board/:id",
  //   name: "LeadBoard",
  //   component: () => import("@/views/LeadBoard"),
  //   meta: { permissionName: "lead" },
  // },
  // {
  //   path: "/lead-board/:id/deal/:dealId",
  //   name: "LeadBoardDeal",
  //   component: LeadBoardDeal,
  //   meta: { permissionName: "lead" },
  // },
  // {
  //   path: "/lead-board/public/:id/deal",
  //   name: "LeadBoardDealPublic",
  //   component: LeadBoardDealPublic,
  // },
  {
    path: "/no-permissions",
    name: "NoPermissions",
    component: NoPermissions,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: SetPassword,
  },
  {
    path: "/first-password",
    name: "FirstPassword",
    component: SetPassword,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let firstLoad = true;

router.beforeEach(async (to, from, next) => {
  const store = router.app.$root.$store;
  const publicRoutes = [
    "Login",
    "FirstPassword",
    "ResetPassword",
    "ForgotPassword",
    "LeadBoardDealPublic",
  ];

  try {
    if (store.state.loggedUser === null && firstLoad) {
      await setCsrfCookie();
      const res = await setUserData();
      await store.commit("SET_USER_DATA", res.data);
      firstLoad = false;
    }
    if (
      !(
        (to.path.includes("our-fleet") && from.path.includes("our-fleet")) ||
        (to.path.includes("clients") && from.path.includes("clients"))
      )
    ) {
      store.commit("CHANGE_SEARCH_STRING", "");
      store.commit("CHANGE_SORT_VARIABLES", { sortBy: null, sortOrder: false });
    }
    const user = store.state.loggedUser;
    if (publicRoutes.includes(to.name) && user === null) {
      next();
    } else {
      const userPermissions = checkUserPermissions(user);

      if (publicRoutes.includes(to.name)) {
        if (to.name === "Login" && user.has2fa && !user.secretSubmitted) next();
        if (to.name !== "Login" && user.has2fa && !user.secretSubmitted)
          next({ name: "Login" });
        if (!userPermissions.permissions.length) {
          next({ name: "NoPermissions" });
        } else next({ name: userPermissions.defaultRoute.name });
      }

      if (to.name !== "Login" && user.has2fa && !user.secretSubmitted)
        next({ name: "Login" });

      if (userPermissions.permissions.includes(to.meta.permissionName)) next();
      else {
        if (!userPermissions.permissions.length) {
          if (to.name === "NoPermissions") next();
          else next({ name: "NoPermissions" });
        } else next({ name: userPermissions.defaultRoute.name });
      }
    }
  } catch (e) {
    if (e.response.status === 401) {
      if (publicRoutes.includes(to.name)) next();
      else next({ name: "Login" });
    } else {
      console.error(e);
    }
  }
});

export default router;
