<template>
  <main ref="carsDashboard" class="d-flex flex-wrap mb-5 h-100">
    <b-overlay :show="fetching" class="d-flex flex-wrap col-12">
      <search-bar
        title="Create new car"
        button-title="Create a car"
        :selected-items="selectedCars"
        :is-loading="isLoading"
        multiple-delete-button-id="kt-button-delete-selected-car"
        permissionName="cars.write"
        delete-permission-name="cars.delete"
        @createNew="isNewCar"
        @changeStatus="fetchCars"
        @searchChanged="searchChanged($event)"
        @deleteSelectedItem="deleteSelectedCars($event)"
      >
      </search-bar>

      <cars-table
        ref="carsTable"
        @sortingChanged="fetchCars"
        @selectedCars="selectCar($event)"
        @deleteCars="deleteSelectedCars($event)"
        @onlyReadCar="onlyReadCar = $event"
      ></cars-table>
    </b-overlay>

    <create-car-sidebar @refreshCars="fetchCars"></create-car-sidebar>

    <div class="d-flex align-items-end flex-column col-12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        next-class="next-page"
        prev-class="prev-page"
        last-class="last-page"
        first-class="first-page"
        size="lg"
        class="mb-0 mt-auto p-3"
        col-12
        @input="fetchCars"
      ></b-pagination>
    </div>
    <!-- END OF PAGINATION -->
  </main>
</template>

<script>
import SearchBar from "@/components/gadgets/SearchBar";
import CarsTable from "@/components/cars/CarsTable";
import CreateCarSidebar from "@/components/cars/car/CreateCarSidebar";

export default {
  name: "CarsDashboard",
  components: {
    CreateCarSidebar,
    CarsTable,
    SearchBar,
  },
  data() {
    return {
      selectedCars: [],
      onlyReadCar: false,
      fetching: false,
      isLoading: false,
    };
  },
  created() {
    this.fetchCars();
    this.fetchCarsMeta();
  },
  computed: {
    currentPage: {
      get() {
        return this.$route.query.page || 1;
      },
      set(fieldValue) {
        this.$router.push({
          name: "CarsDashboard",
          query: { page: fieldValue },
        });
      },
    },
    status() {
      return this.$store.state.status;
    },
    totalRows() {
      return this.$store.state.cars.totalRows;
    },
    perPage() {
      return this.$store.state.perPage;
    },
    globalSearch() {
      return this.$store.state.search;
    },
    sortBy() {
      return this.$store.state.sortBy;
    },
    sortOrder() {
      return this.$store.state.sortOrder;
    },
  },
  methods: {
    isNewCar() {
      this.$root.$emit("bv::toggle::collapse", "createCarSidebar");
    },
    searchChanged() {
      this.currentPage = 1;
      this.fetchCars();
    },
    selectCar(event) {
      this.$set(this, "selectedCars", event);
    },
    async fetchCars() {
      try {
        this.fetching = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars?page=${
            this.currentPage
          }&perPage=${this.perPage}${
            this.status !== "Active" ? "&isDisabled=1" : ""
          }${this.globalSearch ? `&search=${this.globalSearch}` : ""}${
            this.sortBy ? `&sortBy=${this.sortBy}` : ""
          }&sortOrder=${this.sortOrder ? "desc" : "asc"}`
        );
        this.$store.commit("cars/SET_TOTAL_ROWS", res.data.total);
        this.$store.commit("cars/SET_CARS", res.data.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Cars fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.fetching = false;
        this.$refs.carsTable
          ? (this.$refs.carsTable.$children[0].$el.scrollTop = 0)
          : null;
      }
    },
    async fetchCarsMeta() {
      try {
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars/meta`
        );
        this.$store.commit("cars/SET_CARS_META", res.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Companies meta fetching failed",
          variant: "danger",
          solid: true,
        });
      }
    },

    async deleteSelectedCars(car) {
      const checkIfArray = Array.isArray(car);
      let conf = confirm(
        `Are you sure to delete ${
          checkIfArray ? `multiple cars (${car.length})` : `car: ${car.model}`
        }`
      );
      if (conf) {
        this.isLoading = true;
        try {
          await this.deleteRequest(checkIfArray, car);

          this.$bvToast.toast("Success", {
            title: "Delete was successful",
            variant: "success",
            solid: true,
          });
          await this.fetchCars();
          this.$set(this, "selectedCars", []);
          this.$set(this.$refs.carsTable, "selectedCarsId", []);
        } catch (error) {
          console.error(error);
          this.$bvToast.toast(error.response.data.message, {
            title: "Car delete failed",
            variant: "danger",
            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async deleteRequest(checkIfArray, car) {
      if (!checkIfArray) {
        return await this.$axios.delete(
          `${process.env.VUE_APP_API_URL}/api/cars/${car.id}`
        );
      } else {
        return await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/cars/bulk-delete`,
          { cars: car }
        );
      }
    },
  },
};
</script>
