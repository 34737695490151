import lodash from "lodash";
import Vue from "vue";

const state = {
  carData: {
    id: null,
    brand: {},
    brandId: null,
    model: "",
    insuranceNumber: "",
    insuranceNumberFromDate: null,
    insuranceNumberToDate: null,
    warrantyNumberFromDate: null,
    warrantyTo: null,
    registrationNumberFromDate: null,
    registrationTo: null,
    registrationNumber: "",
    vinNumber: "",
    isSold: false,
    warrantyNumber: "",
    typeOfTransaction: null,
    owner: "",
    documents: [],
    invoices: [],
    todoItems: [],
    rents: [],
    isDisabled: false,
    leasingPrice: null,
    leasingFrom: null,
    leasingTo: null,
  },
  invoiceData: {
    invoiceRows: [
      {
        id: 1,
        category: "",
        segment: "",
        cost: "",
        invoiceNumber: "",
        billedOnDate: new Date(),
        validFrom: null,
        validTo: null,
        description: "",
        isAutogenerated: false,
      },
    ],
    categories: [],
    expenseSegments: [],
    incomeSegments: [],
    editModal: false,
    total: [],
  },
  rentData: {
    clientId: null,
    rentId: null,
    deposit: null,
    mileageLimit: null,
    instalmentWithoutTax: null,
    documents: [],
    from: new Date(),
    to: new Date(),
    services: [],
  },
  // Invoices pagination
  total: 0,
  currentPage: 0,
};

const mutations = {
  ADD_TODO_ITEM(state, item) {
    state.carData.todoItems.reverse().push(item);
    state.carData.todoItems.reverse();
  },
  UPDATE_TODO_ITEM(state, { item, index }) {
    state.carData.todoItems.splice(index, 1, item);
  },
  DELETE_TODO_ITEM(state, index) {
    state.carData.todoItems.splice(index, 1);
  },
  ADD_RENT(state, item) {
    state.carData.rents.reverse().push(item);
    state.carData.rents.reverse();
  },
  UPDATE_RENT(state, item) {
    const index = state.carData.rents.findIndex((e) => {
      return e.rentId === item.rentId;
    });
    state.carData.rents.splice(index, 1, item);
  },
  DELETE_RENT(state, id) {
    const index = state.carData.rents.findIndex((e) => {
      return e.rentId === id;
    });
    state.carData.rents.splice(index, 1);
  },
  SET_CAR_DATA(state, car) {
    car.invoices = [];
    state.carData = lodash.cloneDeep(car);
  },
  CHANGE_CAR_FIELD(state, { fieldName, fieldValue }) {
    state.carData[fieldName] = fieldValue;
  },
  SET_CAR_INVOICE_TOTAL(state, data) {
    state.invoiceData.total = data;
  },
  SET_INVOICE_META(state, data) {
    state.invoiceData.categories = data.categories;
    state.invoiceData.expenseSegments = data.expenseSegments;
    state.invoiceData.incomeSegments = data.incomeSegments;
  },
  SET_INITIAL_INVOICES(state, data) {
    Vue.set(state.carData, "invoices", data);
  },
  ADD_INVOICES(state, { data, id }) {
    data.id = id;
    state.carData.invoices.push(data);
  },
  DELETE_CAR_INVOICE(state, id) {
    const index = state.carData.invoices.findIndex((el) => el.id === id);
    if (index !== -1) state.carData.invoices.splice(index, 1);
  },
  ADD_ROW(state) {
    state.invoiceData.invoiceRows.push({
      id: `row-${state.invoiceData.invoiceRows.length + 1}`,
      category: "",
      segment: "",
      cost: "",
      invoiceNumber: "",
      billedOnDate: new Date(),
      validFrom: null,
      validTo: null,
      description: "",
    });
  },
  FILL_CAR_INVOICE_EDIT_MODAL(state, id) {
    const index = state.carData.invoices.findIndex((el) => el.id === id);
    state.invoiceData.editModal = true;
    if (index !== -1)
      state.invoiceData.invoiceRows[0] = state.carData.invoices[index];
  },
  DELETE_ROW(state, id) {
    if (state.invoiceData.invoiceRows.length > 1) {
      let index = state.invoiceData.invoiceRows.findIndex(
        (value) => value === id
      );
      state.invoiceData.invoiceRows.splice(index, 1);
    }
  },
  SET_RENT_DATA(state, value) {
    value.from = new Date(value.from).toISOString().split("T")[0];
    value.to = new Date(value.to).toISOString().split("T")[0];
    state.rentData = value;
  },
  RESET_EXPENSE_FIELDS(state, id) {
    state.invoiceData.invoiceRows[id].description = "";
    state.invoiceData.invoiceRows[id].from = null;
    state.invoiceData.invoiceRows[id].to = null;
  },
  RESET_ROWS(state) {
    state.invoiceData.editModal = false;
    state.invoiceData.invoiceRows = [
      {
        id: "1",
        category: "",
        segment: "",
        cost: "",
        invoiceNumber: "",
        billedOnDate: new Date(),
        validFrom: null,
        validTo: null,
        description: "",
        isAutogenerated: false,
      },
    ];
  },
  RESET_CAR_DATA(state) {
    state.carData = {
      id: null,
      brand: {},
      brandId: null,
      model: "",
      insuranceNumber: "",
      insuranceNumberFromDate: null,
      insuranceNumberToDate: null,
      warrantyNumberFromDate: null,
      warrantyNumberToDate: null,
      registrationNumberFromDate: null,
      registrationNumberToDate: null,
      registrationNumber: "",
      vinNumber: "",
      isSold: false,
      warrantyNumber: "",
      typeOfTransaction: null,
      owner: "",
      documents: [],
      comments: [],
      invoices: [],
      todoItems: [],
      rents: [],
      isDisabled: false,
    };
  },
  RESET_RENT_DATA(state) {
    state.rentData = {
      clientId: null,
      rentId: null,
      deposit: null,
      mileageLimit: null,
      instalmentWithoutTax: null,
      from: new Date(),
      to: new Date(),
      services: [],
    };
  },
  CHANGE_CAR_INVOICE_FIELD(state, { fieldName, fieldValue, id }) {
    state.invoiceData.invoiceRows[id][fieldName] = fieldValue;
  },
  CHANGE_CURRENT_PAGE(state, value) {
    state.currentPage = value;
  },
  CHANGE_TOTAL(state, value) {
    state.total = value;
  },
};

const actions = {};

const getters = {
  getCarFieldData: (state) => (fieldName) => {
    return state.carData[fieldName];
  },
  getCarInvoiceData: (state) => (id, fieldName) => {
    return state.invoiceData.invoiceRows[id][fieldName];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
