<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-wrap filter-board__top">
      <div class="d-flex align-items-start w-100 justify-content-between">
        <h3>{{ title }}</h3>

        <div class="d-flex flex-wrap">
          <fu-button
            id="search-income"
            title="Clear"
            is-small
            variant="white"
            :is-loading="isLoading"
            @click="clearFilter"
          />
          <fu-button
            id="search-income"
            title="Search"
            is-small
            :is-loading="isLoading"
            @click="filter"
          />
        </div>
      </div>
      <multiselect
        v-model="car"
        :options="cars"
        :loading="isLoading"
        placeholder="Car"
        class="multiselect mt-2 col-4 pl-0"
        :custom-label="carLabel"
        @search-change="searchCar = $event"
      >
        <template slot="placeholder">Car </template>

        <template slot="option" slot-scope="props">
          <div
            class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
          >
            <span
              >{{ props.option.brand.name }} {{ props.option.model }}
              <i class="far fa-long-arrow-alt-right"></i>
              {{ props.option.registrationNumber }}</span
            >
          </div>
        </template>

        <template slot="noResult">
          <div class="d-flex flex-wrap align-items-center">
            <span>No car found: "{{ searchCar }}" </span>
          </div>
        </template>
      </multiselect>

      <multiselect
        v-if="!car"
        v-model="brand"
        :options="brands"
        :loading="isLoading"
        placeholder="Brand"
        class="multiselect mt-2 col-3 pl-0"
        :disabled="isLoading"
        label="name"
        @search-change="searchBrand = $event"
      >
        <template slot="placeholder">Brand </template>

        <template slot="option" slot-scope="props">
          <div
            class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
          >
            <span>{{ props.option.name }} </span>
          </div>
        </template>

        <template slot="noResult">
          <div class="d-flex flex-wrap align-items-center">
            <span>No brand found: "{{ searchCar }}" </span>
          </div>
        </template>
      </multiselect>

      <multiselect
        v-if="!car"
        v-model="model"
        :options="models"
        :loading="isLoading"
        :disabled="isLoading"
        placeholder="Model"
        class="multiselect mt-2 col-3 pl-0"
        label="name"
        @search-change="searchBrand = $event"
      >
        <template slot="placeholder">Model </template>

        <template slot="option" slot-scope="props">
          <div
            class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
          >
            <span>{{ props.option.name }} </span>
          </div>
        </template>

        <template slot="noResult">
          <div class="d-flex flex-wrap align-items-center">
            <span>No model found: "{{ searchCar }}" </span>
          </div>
        </template>
      </multiselect>

      <multiselect
        v-model="segment"
        :options="typeCode === 'expense' ? expenseSegments : incomeSegments"
        :loading="isLoading"
        placeholder="Segment"
        class="multiselect mt-2 col-3 pl-0"
        label="name"
        @search-change="searchSegment = $event"
      >
        <template slot="placeholder">Segment </template>

        <template slot="option" slot-scope="props">
          <div
            class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
          >
            <span>{{ props.option.name }}</span>
          </div>
        </template>

        <template slot="noResult">
          <div class="d-flex flex-wrap align-items-center">
            <span>No segment found: "{{ searchClient }}" </span>
          </div>
        </template>
      </multiselect>

      <validation-provider
        tag="label"
        rules="required"
        :name="`date-from-expense`"
        v-slot="{ errors }"
        class="col-3 mt-2 pl-0 mr-3"
      >
        <date-picker
          v-model="fromDate"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker multiselect date-picker-border"
              :value="inputValue"
              placeholder="Date from"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        rules="required"
        :name="`date-to-income`"
        v-slot="{ errors }"
        class="col-3 mt-2 pl-0"
      >
        <date-picker
          v-model="toDate"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker multiselect date-picker-border"
              :value="inputValue"
              placeholder="Date to"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <filter-response
      :response="response"
      :total="total"
      :type-code="typeCode"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import lodash from "lodash";
import {DatePicker} from "v-calendar";
import FilterResponse from "@/components/analytics/FilterResponse.vue";

export default {
  name: "FilterBoard",
  components: { FilterResponse, DatePicker },
  props: {
    typeCode: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      datePickerConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        format: "YYYY-MM-DD",
      },

      // SEARCH STRINGS
      searchCar: "",
      searchBrand: "",
      searchModel: "",
      searchClient: "",
      searchSegment: "",

      // VALUES
      car: null,
      brand: null,
      model: null,
      segment: null,
      fromDate: null,
      toDate: null,

      // MULTISELECT OPTIONS ARRAY
      cars: [],
      models: [],

      // NEW: MOCK DATA OF ANALYTICS RESPONSE
      response: [],
      total: {},
    };
  },
  computed: {
    ...mapState({
      perPage: (state) => state.perPage,
      brands: (state) => state.analytics.meta.brands,
      expenseSegments: (state) => state.analytics.meta.expenseSegments,
      incomeSegments: (state) => state.analytics.meta.incomeSegments,
      categories: (state) => state.analytics.meta.categories,
    }),
    concatApiEndpoint() {
      let endpoint = "";
      if (this.car) endpoint += `carId=${this.car.id}&`;
      if (this.brand) endpoint += `brandId=${this.brand.id}&`;
      if (this.model) endpoint += `model=${this.model.name}&`;
      if (this.segment) endpoint += `segmentId=${this.segment.id}&`;
      if (this.fromDate)
        endpoint += `fromDate=${this.formatDate(this.fromDate)} &`;
      if (this.toDate) endpoint += `toDate=${this.formatDate(this.toDate)}&`;
      return endpoint;
    },
  },
  watch: {
    searchCar(value) {
      this.searchCars(value);
    },
    brand(value) {
      this.model = null;
      this.fetchModel(value ? value.id : null);
    },
    car(value) {
      if (value && (this.brand || this.model)) {
        this.brand = null;
        this.model = null;
      }
    },
  },
  methods: {
    clearFilter() {
      this.car = null;
      this.brand = null;
      this.segment = null;
      this.toDate = null;
      this.fromDate = null;
      this.model = null;
      this.$set(this, "response", []);
    },
    carLabel({ brand, model, registrationNumber }) {
      return `${brand.name} ${model} - ${registrationNumber}`;
    },
    overlay(value) {
      this.isLoading = value;
      this.$emit("filtering", value);
    },
    async fetchModel(id) {
      try {
        this.isLoading = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/analytics/models?brandId=${id}`
        );
        this.$set(this, "models", res.data);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    searchCars: lodash.debounce(async function (value) {
      try {
        this.isLoading = true;
        if (value) {
          const res = await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/cars?page=1&perPage=${
              this.perPage
            }&isDisabled=0&search=${value ? value : ""}${
              this.sortBy ? `&sortBy=${this.sortBy}` : ""
            }&sortOrder=${this.sortOrder ? "desc" : "asc"}`
          );
          this.$set(this, "cars", res.data.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }, 500),
    formatDate(date) {
      return new Date(date).toISOString().split("T")[0];
    },
    async filter() {
      try {
        this.overlay(true);
        const categoryId = this.categories[
          this.categories.findIndex((el) => el.code === this.typeCode)
        ].id;

        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/analytics?categoryId=${categoryId}&type=${this.typeCode}&${this.concatApiEndpoint}`
        );
        if (res.data && res.data.length > 0) {
          this.total = res.data.pop();
        }
        this.$set(this, "response", res.data);
      } catch (e) {
        console.error(e);
      } finally {
        this.overlay(false);
      }
    },
  },
};
</script>
