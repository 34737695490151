<template>
  <div class="col-lg-5 col-5 px-4">
    <h5 class="pb-3 text-capitalize fu-deal__column-title">Car activity</h5>

    <car-activity-todo-list />

    <car-activity-comments :initial-fetch="initialFetch" />
  </div>
</template>

<script>
import CarActivityTodoList from "@/components/cars/car/activity/CarActivityTodoList";
import CarActivityComments from "@/components/cars/car/activity/CarActivityComments";
export default {
  name: "CarActivity",
  components: { CarActivityComments, CarActivityTodoList },
  props: {
    initialFetch: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
