<template>
  <div class="d-flex flex-wrap col-12 p-0 mt-5">
    <b-table
      table-class="fu-table"
      bordered
      hover
      sticky-header="55vh"
      :sort-compare-options="{ numeric: true, ignorePunctuation: true }"
      @sort-changed="sortChanged"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortOrder"
      :items="users"
      :fields="userTableComputed"
      :per-page="itemsPerPage"
    >
      <template #head(select)>
        <b-form-checkbox
          :checked="allSelectedOnPage"
          @change="selectAll"
        ></b-form-checkbox>
      </template>

      <template #cell(firstName)="{ value }">
        <span>{{ value }}</span>
      </template>

      <template #cell(select)="{ item }">
        <b-form-checkbox
          :checked="selectedUsersId.includes(item.id)"
          @change="selectUser(item.id)"
        ></b-form-checkbox>
      </template>
      <template #cell(permissions)="{ value }">
        <p
          class="text-capitalize mb-0"
          :class="{ 'w-100 text-left': !userHasPermission }"
        >
          {{ value }}
        </p>
      </template>
      <template #cell(actions)="{ item }">
        <div
          class="d-flex flex-wrap align-items-center justify-content-center w-100"
        >
          <auth-action permission-name="users.write">
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="editUser(item.id)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
                class="far fa-edit fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>

          <auth-action permission-name="users.delete">
            <button
              class="fu-button__transparent px-0"
              @click="deleteUser(item)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
                class="far fa-trash-alt fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "UsersTable",
  data() {
    return {
      allSelectedOnPage: false,
      fields: [
        {
          key: "select",
          sortable: false,
          label: "",
        },
        {
          key: "firstName",
          sortable: true,
          label: "Name",
          formatter: (value, key, item) => {
            return item.firstName + " " + item.lastName;
          },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "position.name",
          sortable: true,
          label: "Position",
          formatter: (value, key, item) => {
            return item.position ? item.position.name : "";
          },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "phone",
          sortable: false,
        },
        {
          key: "permissions",
          sortable: false,
          formatter: (value) => {
            return value
              .map(({ name }) => {
                return name.split(".")[0].replace("_", " ");
              })
              .filter((value, index, array) => array.indexOf(value) === index)
              .join(", ");
          },
        },
        {
          key: "actions",
          sortable: false,
          label: "",
        },
      ],
      selectedUsersId: [],
    };
  },
  computed: {
    sortBy: {
      get() {
        return this.$store.state.sortBy;
      },
      set(fieldValue) {
        this.$store.commit("CHANGE_SORT_PARAMETER", {
          fieldName: "sortBy",
          fieldValue,
        });
      },
    },
    sortOrder: {
      get() {
        return this.$store.state.sortOrder;
      },
      set(fieldValue) {
        this.$store.commit("CHANGE_SORT_PARAMETER", {
          fieldName: "sortOrder",
          fieldValue,
        });
      },
    },
    users() {
      return this.$store.state.users.users;
    },
    userHasPermission() {
      let readPer = false;
      let writePer = false;
      let deletePer = false;
      for (let permission of this.$store.state.loggedUser.permissions) {
        if (permission.name.includes("users.read")) {
          readPer = true;
        }
        if (permission.name.includes("users.write")) {
          writePer = true;
        }
        if (permission.name.includes("users.delete")) {
          deletePer = true;
        }
      }
      if (readPer && !writePer && !deletePer) {
        return false;
      }
      return true;
    },
    itemsPerPage() {
      return this.$store.state.perPage;
    },
    userTableComputed() {
      //This methods checks if the user only has the read permission
      let readPer = false;
      let writePer = false;
      let deletePer = false;
      //Loops thru the array of user permissions and checks if the user has read,write and delete permissions
      for (let permission of this.$store.state.loggedUser.permissions) {
        if (permission.name.includes("users.read")) {
          readPer = true;
        }
        if (permission.name.includes("users.write")) {
          writePer = true;
        }
        if (permission.name.includes("users.delete")) {
          deletePer = true;
        }
      }
      //Creates a new array
      let temp;
      //The fields array and newarray are now the saem
      temp = this.fields;

      //Checks the users permission
      if (readPer && !writePer && !deletePer) {
        //If the user doesnt have the action permissions it pops the last column
        temp.pop();
      }
      // Returns the appropriate array
      return temp;
    },
  },
  watch: {
    users() {
      this.checkIfAllSelected();
    },
    selectedUsersId(value) {
      this.$emit("selectedUsers", value);
      this.checkIfAllSelected();
    },
  },
  methods: {
    async sortChanged() {
      this.$nextTick(() => {
        this.$emit("sortingChanged");
      });
    },
    checkIfAllSelected() {
      const usersIds = this.users.map(({ id }) => id);
      let count = 0;
      usersIds.forEach((userId) => {
        if (this.selectedUsersId.includes(userId)) count++;
      });

      this.allSelectedOnPage =
        usersIds.length === count && usersIds.length !== 0;
    },
    selectUser(userId) {
      if (!this.selectedUsersId.includes(userId)) {
        this.selectedUsersId.push(userId);
      } else {
        const index = this.selectedUsersId.indexOf(userId);
        if (index > -1) {
          this.selectedUsersId.splice(index, 1);
        }
      }
    },
    selectAll(value) {
      this.users.forEach((user) => {
        if (value) {
          if (!this.selectedUsersId.includes(user.id)) {
            this.selectedUsersId.push(user.id);
          }
        } else {
          const index = this.selectedUsersId.indexOf(user.id);
          if (index > -1) this.selectedUsersId.splice(index, 1);
        }
      });
    },
    editUser(selectedId) {
      const user = this.users.find(({ id }) => id === selectedId);
      this.$store.commit("user/SET_USER_DATA", user);
      this.$emit("isNewUser", false);
    },
    deleteUser(selectedUser) {
      this.$emit("deleteUser", selectedUser);
    },
  },
};
</script>
