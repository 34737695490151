<template>
  <main>
    <b-overlay :show="isLoading" class="d-flex flex-wrap col-12">
      <car-details-header
        :is-loading="isLoading"
        @saveChanges="saveChanges"
      ></car-details-header>

      <validation-observer
        ref="carDetailsDashboardForm"
        class="d-flex flex-wrap justify-content-between col-12 px-0"
      >
        <car-details :is-loading="isLoading"></car-details>

        <car-activity :initial-fetch="isLoading"></car-activity>
        <car-rents
          :is-loading="isLoading"
          @isLoading="isLoading = $event"
        ></car-rents>
      </validation-observer>
    </b-overlay>
  </main>
</template>

<script>
import CarDetailsHeader from "@/components/cars/details/CarDetailsHeader";
import CarActivity from "@/components/cars/car/CarActivity";
import CarDetails from "@/components/cars/car/CarDetails";
import CarRents from "@/components/cars/car/CarRents";
import { mapState } from "vuex";
export default {
  name: "CarExtendedView",
  components: {
    CarDetailsHeader,
    CarActivity,
    CarDetails,
    CarRents,
  },
  data() {
    return {
      isLoading: false,
      isFetching: false,
    };
  },
  computed: {
    ...mapState({
      currentPage: (state) => state.car.currentPage,
      perPage: (state) => state.perPage,
    }),
    total: {
      get() {
        return this.$store.state.car.total;
      },
      set(value) {
        this.$store.commit("car/CHANGE_TOTAL", value);
      },
    },
  },
  beforeMount() {
    this.fetchSelectedCar();
    this.fetchCarsMeta();
  },
  beforeDestroy() {
    this.$store.commit("car/RESET_CAR_DATA");
  },
  methods: {
    async fetchSelectedCar() {
      try {
        this.isLoading = true;
        this.$store.commit("car/CHANGE_CURRENT_PAGE", 0);
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}`
        );
        const res2 = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/?page=${this.currentPage}&perPage=${this.perPage}`
        );
        this.$store.commit("car/SET_CAR_DATA", res.data);
        await this.$store.commit("car/SET_INITIAL_INVOICES", res2.data.data);
        this.total = res2.data.total;
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Car fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async saveChanges() {
      this.$refs.carDetailsDashboardForm.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;

            const res = await this.$axios.put(
              `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}`,
              this.$store.state.car.carData
            );
            const res2 = await this.$axios.get(
              `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/?page=${this.currentPage}&perPage=${this.perPage}`
            );
            await this.$store.commit("car/SET_CAR_DATA", res.data);
            this.total = res2.data.total;
            await this.$store.commit(
              "car/SET_INITIAL_INVOICES",
              res2.data.data
            );
            this.$bvToast.toast("Car was updated", {
              title: `Success`,
              variant: "success",
              solid: true,
            });
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Updating car details failed", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    async fetchCarsMeta() {
      try {
        this.isLoading = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars/meta`
        );
        const res2 = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/meta`
        );
        const total = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/total`
        );

        this.$store.commit("cars/SET_CARS_META", res.data);
        this.$store.commit("car/SET_INVOICE_META", res2.data);
        this.$store.commit("car/SET_CAR_INVOICE_TOTAL", total.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Companies meta fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
