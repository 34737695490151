import Vue from "vue";

const state = {
  totalRows: 0,
  clients: [],
  clientsMeta: {
    users: [],
  },
};

const mutations = {
  SET_CLIENTS(state, clients) {
    Vue.set(state, "clients", clients);
  },
  SET_CLIENTS_META(state, clients) {
    Vue.set(state, "clientsMeta", clients);
  },
  SET_TOTAL_ROWS(state, totalPaginationRows) {
    Vue.set(state, "totalRows", totalPaginationRows);
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
