<template>
  <div ref="dealDetailsWrapper" class="col-lg-3 col-6 px-0 fu-deal__column">
    <h5 class="pb-3 text-capitalize fu-deal__column-title">Car details</h5>
    <validation-observer
      ref="dealDetailsForm"
      tag="div"
      class="col-12 pl-0 mb-4 fu-deal__column-wrapper"
    >
      <create-car-form
        :is-loading="isLoading"
        form-class="d-flex flex-wrap col-12 px-0 mb-4"
      ></create-car-form>
    </validation-observer>
  </div>
</template>

<script>
import CreateCarForm from "@/components/cars/car/CreateCarForm";
export default {
  name: "CarDetails",
  components: { CreateCarForm },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
