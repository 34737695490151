<template>
  <div class="w-100">
    <documents-modal
      :documents="selectedFolder.documents"
      :type="type"
      :only-read-client="onlyReadClient"
      @addDocument="addDocument"
      @removeDocument="removeDocument"
    ></documents-modal>
    <b-row class="d-flex justify-content-end w-100 mb-4">
      <fu-button
        v-if="!onlyReadClient"
        title="Add new folder"
        id="addFolderButton"
        @click="addNewFolder"
      >
      </fu-button>
    </b-row>
    <b-row>
      <b-col class="col-4" v-for="(folder, index) in folders" :key="index">
        <i
          class="far fa-folder fa-3x folder"
          @click="showDocuments(folder)"
        ></i>
        <div v-if="!onlyReadClient" class="delete_icon">
          <i class="far fa-times folder" @click="removeFolder(index)"></i>
        </div>
        <input
          :disabled="onlyReadClient"
          class="d-block col-11 p-0"
          v-model="folder.name"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DocumentsModal from "@/components/shared/DocumentsModal";

export default {
  name: "Folders",
  components: { DocumentsModal },
  data() {
    return {
      selectedFolder: {},
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    onlyReadClient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    folders() {
      if (this.type === "car") return this.$store.state.car.carData.folders;
      else return this.$store.state.client.clientData.folders;
    },
  },
  methods: {
    removeFolder(index) {
      this.folders.splice(index, 1);
    },
    addDocument(event) {
      this.selectedFolder.documents.push(event);
    },
    removeDocument(index) {
      this.selectedFolder.documents.splice(index, 1);
    },
    showDocuments(folder) {
      this.selectedFolder = folder;
      this.$bvModal.show("documentsModal");
    },
    addNewFolder() {
      this.folders.push({
        id: null,
        name: "New Folder",
        documents: [],
      });
    },
  },
};
</script>

<style scoped>
.delete_icon {
  position: absolute;
  top: 0;
  right: 20px;
  color: black;
  padding: 0 0.3rem;
}
input {
  border: none !important;
  text-overflow: ellipsis;
}
.folder {
  cursor: pointer;
}
</style>
