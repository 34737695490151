<template>
  <div class="d-flex flex-wrap col-12 p-0 mt-5">
    <b-table
      table-class="fu-table"
      bordered
      hover
      sticky-header="55vh"
      :sort-compare-options="{ numeric: true, ignorePunctuation: true }"
      @sort-changed="sortChanged"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortOrder"
      :items="cars"
      :fields="fields"
      :per-page="itemsPerPage"
    >
      <template #head(select)>
        <b-form-checkbox
          v-model="allSelectedOnPage"
          @change="selectAll"
        ></b-form-checkbox>
      </template>

      <template #cell(select)="{ item }">
        <b-form-checkbox
          :checked="selectedCarsId.includes(item.id)"
          @change="selectCar(item.id)"
        ></b-form-checkbox>
      </template>
      <template #cell(transactionTypes)="{ item }">
        {{ getTransactionTypesString(item.transactionTypes) }}
      </template>

      <template #cell(actions)="{ item }">
        <div
          class="d-flex flex-wrap align-items-center justify-content-center w-100"
        >
          <auth-action permission-name="cars.read">
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="editCar(item.id, true)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                class="far fa-eye fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>

          <auth-action permission-name="cars.write">
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="editCar(item.id, false)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
                class="far fa-edit fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>
          <auth-action permission-name="cars.delete">
            <button
              class="fu-button__transparent px-0"
              @click="deleteCars(item)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
                class="far fa-trash-alt fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import AuthAction from "@/components/gadgets/AuthAction";

export default {
  name: "CarsTable",
  components: { AuthAction },
  data() {
    return {
      allSelectedOnPage: false,
      fields: [
        {
          key: "select",
          sortable: false,
          label: "",
        },
        {
          key: "brand.name",
          sortable: true,
          label: "Brand",
        },
        {
          key: "model",
          sortable: true,
        },
        {
          key: "registrationNumber",
          sortable: true,
        },
        {
          key: "vinNumber",
          sortable: true,
        },
        {
          key: "transactionTypes",
          sortable: false,
        },
        {
          label: "Warranty Number",
          key: "warrantyNumber",
          sortable: true,
        },
        {
          key: "owner",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
          label: "",
        },
      ],
      selectedCarsId: [],
    };
  },
  watch: {
    cars() {
      this.checkIfAllSelected();
    },
    selectedCarsId(value) {
      this.$emit("selectedCars", value);
      this.checkIfAllSelected();
    },
  },
  computed: {
    cars() {
      return this.$store.state.cars.cars;
    },
    itemsPerPage() {
      return this.$store.state.perPage;
    },
    sortBy: {
      get() {
        return this.$store.state.sortBy;
      },
      set(fieldValue) {
        this.$store.commit("CHANGE_SORT_PARAMETER", {
          fieldName: "sortBy",
          fieldValue,
        });
      },
    },
    sortOrder: {
      get() {
        return this.$store.state.sortOrder;
      },
      set(fieldValue) {
        this.$store.commit("CHANGE_SORT_PARAMETER", {
          fieldName: "sortOrder",
          fieldValue,
        });
      },
    },
  },
  methods: {
    async sortChanged() {
      this.$nextTick(() => {
        this.$emit("sortingChanged");
      });
    },
    getTransactionTypesString(array) {
      return array
        .map(function (e) {
          return e.name;
        })
        .toString();
    },
    checkIfAllSelected() {
      const carsIds = this.cars.map(({ id }) => id);
      let count = 0;
      carsIds.forEach((carId) => {
        if (this.selectedCarsId.includes(carId)) count++;
      });

      this.allSelectedOnPage = carsIds.length === count && carsIds.length !== 0;
    },
    selectCar(carId) {
      if (!this.selectedCarsId.includes(carId)) {
        this.selectedCarsId.push(carId);
      } else {
        const index = this.selectedCarsId.indexOf(carId);
        if (index > -1) this.selectedCarsId.splice(index, 1);
      }
    },
    selectAll(value) {
      this.cars.forEach((car) => {
        if (value) {
          if (!this.selectedCarsId.includes(car.id)) {
            this.selectedCarsId.push(car.id);
          }
        } else {
          const index = this.selectedCarsId.indexOf(car.id);
          if (index > -1) this.selectedCarsId.splice(index, 1);
        }
      });
    },
    async editCar(selectedId, onlyRead) {
      const car = this.cars.find(({ id }) => id === selectedId);
      this.$store.commit("car/SET_CAR_DATA", car);
      this.$emit("onlyReadCar", onlyRead);
      await this.$router.push({
        name: "CarExtended",
        params: { id: selectedId },
      });
    },
    deleteCars(selectedCar) {
      this.$emit("deleteCars", selectedCar);
    },
  },
};
</script>
