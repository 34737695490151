var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("First name*")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-first-name","type":"text","name":"first name","required":"","placeholder":"First name","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Last name *")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-last-name","type":"text","name":"last name","required":"","placeholder":"Last name","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","rules":"required|email|max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Email *")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-email","type":"email","name":"email","required":"","placeholder":"Email","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","name":"phone","rules":{ max: 30, regex: /^[+]?[0-9]*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Phone number*")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-phone","type":"text","name":"phone","placeholder":"Phone number","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Address*")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-address","type":"text","name":"address","placeholder":"Address","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","name":"Driving license number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Driving license number")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.drivingLicenseNumber),expression:"drivingLicenseNumber"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-driving-license-number","type":"text","name":"driving license number","placeholder":"driving license number","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.drivingLicenseNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.drivingLicenseNumber=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","name":"tax number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Tax number*")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.taxNumber),expression:"taxNumber"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-tax-number","type":"text","name":"tax number","placeholder":"Tax number","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.taxNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.taxNumber=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","name":"discount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Discount (%)*")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.discount),expression:"discount"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-discount","type":"text","name":"discount","placeholder":"Discount","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.discount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.discount=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","name":"Identification number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Identification number")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.idNumber),expression:"idNumber"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-id-number","type":"text","name":"identification number","placeholder":"Identification number","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.idNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.idNumber=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 px-0"},[_c('validation-provider',{staticClass:"col-12 px-0",attrs:{"tag":"label","name":"Identification document number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Identification document number")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.idDocumentNumber),expression:"idDocumentNumber"}],staticClass:"fu-login__input",attrs:{"id":"kt-input-client-id-document-number","type":"text","name":"identification document number","placeholder":"Identification document number","disabled":_vm.isLoading || _vm.onlyReadClient},domProps:{"value":(_vm.idDocumentNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.idDocumentNumber=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])}),(!_vm.onlyReadClient)?_c('span',{staticClass:"w-100 font-weight-bold mt-5"},[_vm._v("Client users")]):_vm._e(),(!_vm.onlyReadClient)?_c('validation-provider',{staticClass:"col-12 px-0 pr-0",attrs:{"tag":"div","name":"Users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{staticClass:"multiselect kt-div-input",class:{ multiselect__disabled: _vm.isLoading },attrs:{"options":_vm.allUsers,"loading":_vm.isLoading,"custom-label":_vm.userFullName,"track-by":"id","multiple":true,"disabled":_vm.isLoading,"close-on-select":false,"clear-on-select":false,"preserve-search":true},on:{"search-change":function($event){_vm.searchValue = $event}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"kt-select-item"},[_vm._v(_vm._s(((props.option.firstName) + " " + (props.option.lastName))))]),(_vm.isSelectedUser(props.option))?_c('span',[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])]}},{key:"selection",fn:function(ref){
var values = ref.values;
return [(values.length)?_c('div',_vm._l((values),function(value,index){return _c('span',{key:value.id,staticClass:"multiselect__single mx-1 multiselect__multi"},[_vm._v(_vm._s(value.firstName + " " + value.lastName)+" "),(index < values.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),0):_vm._e()]}}],null,true),model:{value:(_vm.clientUsers),callback:function ($$v) {_vm.clientUsers=$$v},expression:"clientUsers"}},[_c('template',{slot:"placeholder"},[_vm._v(" User")]),_c('template',{slot:"noResult"},[_c('span',[_vm._v("No position found: \""+_vm._s(_vm.searchValue)+"\" ")])])],2),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4158351757)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }