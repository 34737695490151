<template>
  <b-modal
    id="carInvoiceModal"
    ref="carInvoiceModal"
    centered
    content-class="fu-modal"
    header-class="fu-modal__header fu-modal__submit-header"
    body-class="fu-modal__body invoice-modal"
    footer-class="fu-modal__footer"
    @hide="closeModal"
  >
    <template #modal-header>
      <h3 class="mb-0">{{ !isEdit ? "Add" : "Edit" }} incomes/expenses</h3>
    </template>

    <div class="col-12">
      <validation-observer ref="carInvoicesForm" :disabled="true">
        <div class="invoice-row-wrapper">
          <car-invoice-row
            v-for="(item, index) in rows"
            :key="`invoice-rows-${index}`"
            :id="item.id"
            :index="index"
            :is-loading="isLoading"
            @deleteRow="deleteRow(item)"
          />
        </div>
      </validation-observer>
    </div>

    <template #modal-footer>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between w-100"
      >
        <div v-if="!isEdit" class="w-100 another-invoice">
          <button @click="addRow">Add another +</button>
        </div>
        <button class="button-transparent mt-2" @click="closeModal">
          Cancel
        </button>

        <fu-button
          v-if="!isEdit"
          @click="addNewInvoice"
          title="Save"
          :disabled="isLoading"
          class="add-invoices-button mt-2"
          id="addNewInvoicesButton"
        />

        <fu-button
          v-else
          @click="editInvoice"
          title="Save"
          :disabled="isLoading"
          class="add-invoices-button mt-2"
          id="addNewInvoicesButton"
        />
      </div>
    </template>
  </b-modal>
</template>

<script>
import CarInvoiceRow from "@/components/cars/car/modal/CarInvoiceRow.vue";

export default {
  name: "CarInvoiceModal",
  components: { CarInvoiceRow },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      pass: false,
      datePickerConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        format: "YYYY-MM-DD",
      },
    };
  },
  computed: {
    clients() {
      return this.$store.state.cars.carsMeta.clients;
    },
    rows() {
      return this.$store.state.car.invoiceData.invoiceRows;
    },
    isEdit() {
      return this.$store.state.car.invoiceData.editModal;
    },
  },
  methods: {
    addRow() {
      this.$store.commit("car/ADD_ROW");
    },
    deleteRow(id) {
      this.$store.commit("car/DELETE_ROW", id);
    },
    dateFormatter(date) {
      return `${new Date(date).toLocaleDateString()}`;
    },
    resetData() {
      this.$store.commit("car/RESET_ROWS");
    },
    closeModal() {
      let conf = null;
      if (!this.pass) conf = confirm("Do you really want to close modal?");

      if (conf || this.pass) {
        this.resetData();
        this.$refs.carInvoiceModal.hide();
        this.pass = false;
      }
    },
    async addNewInvoice() {
      this.$refs.carInvoicesForm.validate().then(async (success) => {
        if (success) {
          try {
            await Promise.all(
              this.$lodash.cloneDeep(this.rows).map(async (el, index) => {
                try {
                  el.billedOnDate = new Date(el.billedOnDate)
                    .toISOString()
                    .split("T")[0];
                  el.categoryId = el.category.id;
                  el.segmentId = el.segment.id;

                  el.cost = el.cost.replace(".", "");
                  el.cost = el.cost.replace(",", ".");

                  this.$emit("isLoading", true);
                  const res = await this.$axios.post(
                    `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances`,
                    el
                  );

                  await this.$store.commit("car/ADD_INVOICES", {
                    data: this.rows[index],
                    id: `${res.data.id}`,
                  });
                  this.$bvToast.toast("Incomes/Expenses added", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                  });
                } catch (e) {
                  console.error(e);
                  this.$bvToast.toast("Car incomes/expenses failed to add", {
                    title: `Failed`,
                    variant: "danger",
                    solid: true,
                  });
                } finally {
                  this.$emit("isLoading", false);
                }
              })
            );

            const total = await this.$axios.get(
              `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/total`
            );
            this.$store.commit("car/SET_CAR_INVOICE_TOTAL", total.data);
            this.pass = true;
            this.closeModal();
          } catch (e) {
            console.error(e);
          }
        }
      });
    },
    async editInvoice() {
      this.$refs.carInvoicesForm.validate().then(async (success) => {
        if (success) {
          try {
            this.rows[0].billedOnDate = new Date(this.rows[0].billedOnDate)
              .toISOString()
              .split("T")[0];
            this.rows[0].categoryId = this.rows[0].category.id;
            this.rows[0].segmentId = this.rows[0].segment.id;

            this.rows[0].cost = this.rows[0].cost.replace(".", "");
            this.rows[0].cost = this.rows[0].cost.replace(",", ".");

            this.$emit("isLoading", true);
            await this.$axios.put(
              `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/${this.rows[0].id}`,
              this.rows[0]
            );
            const total = await this.$axios.get(
              `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/finances/total`
            );
            this.$store.commit("car/SET_CAR_INVOICE_TOTAL", total.data);

            this.$bvToast.toast("Incomes/Expenses edited", {
              title: `Success`,
              variant: "success",
              solid: true,
            });
            this.pass = true;
            this.closeModal();
          } catch (e) {
            console.error(e);
          } finally {
            this.$emit("isLoading", false);
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>
