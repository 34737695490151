<template>
  <div class="col-12 px-0 fu-deal__column-wrapper-activity">
    <div
      ref="carActivityWrapper"
      class="col-12 px-0 mb-3 fu-deal__column-activity"
    >
      <h6 class="text-capitalize mb-2 fu-deal__column-title">Todo list:</h6>
      <p
        v-if="todoItems ? !todoItems.length : false"
        class="d-flex align-items-center justify-content-center pt-5 fu-deal__column-activity__empty"
      >
        There are no tasks on todo list
      </p>

      <div
        v-for="(value, index) in todoItems"
        :key="index"
        class="d-flex flex-wrap align-items-center justify-content-between col-12 p-0 pl-1 mb-1"
        :class="{ 'mt-4': index === 0 }"
      >
        <b-form-checkbox
          v-model="value.isChecked"
          @change="updateTask(value, index)"
          class="text-capitalize mb-2"
          :disabled="isLoading"
          ><span
            class="text-break fu-permission__label"
            :class="{ 'fu-deal__column-activity__crossed': value.isChecked }"
            >{{ value.name }}</span
          >
          <span v-if="value.user" class="mx-3">
            <i class="fas fa-arrow-right"></i>
            <span
              class="ml-3"
              :class="{ 'fu-deal__column-activity__crossed': value.isChecked }"
            >
              {{ value.user.firstName + " " + value.user.lastName }}</span
            ></span
          >
        </b-form-checkbox>
        <button
          class="fu-button__transparent fu-deal__todo-delete pr-3"
          :disabled="isLoading"
          @click.stop="deleteTask(value.id, index)"
        >
          <i class="far fa-minus-circle"></i>
        </button>
      </div>
    </div>
    <div
      class="d-flex flex-wrap justify-content-between align-items-start col-12 px-0"
    >
      <validation-observer
        ref="todoTaskActivityForm"
        class="col-9 px-0 d-flex justify-content-start"
        :disabled="true"
      >
        <validation-provider
          tag="div"
          name="todo task"
          rules="required"
          class="col-9"
          v-slot="{ errors }"
        >
          <input
            v-model="newTask.text"
            type="text"
            placeholder="Add task"
            class="mr-3 fu-deal__column-activity__input"
            :disabled="isLoading"
            @keyup.enter="addNewTask"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>

        <select
          v-model="newTask.userId"
          type="text"
          class="col-4 my-0 py-1 fu-login__input fu-select"
          :disabled="isLoading"
        >
          <option :key="null" :value="null">Assign user</option>
          <option v-for="option in users" :key="option.id" :value="option.id">
            {{ option.firstName + " " + option.lastName }}
          </option>
        </select>
      </validation-observer>

      <button
        class="col-2 px-0 fu-deal__column-activity__button"
        :disabled="isLoading"
        @click="addNewTask"
      >
        Add task
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarActivity",
  data() {
    return {
      isLoading: false,
      newTask: {
        text: null,
        userId: null,
      },
    };
  },
  computed: {
    users() {
      return this.$store.state.cars.carsMeta.users;
    },
    todoItems: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("todoItems");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "todoItems",
          fieldValue,
        });
      },
    },
  },
  methods: {
    async addNewTask() {
      this.$refs.todoTaskActivityForm.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;

            const res = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/todo-items`,
              {
                name: this.newTask.text,
                userId: this.newTask.userId,
                isChecked: false,
              }
            );

            await this.$store.commit("car/ADD_TODO_ITEM", res.data);
            this.$bvToast.toast("Car todo task added", {
              title: `Success`,
              variant: "success",
              solid: true,
            });

            this.$nextTick(() => {
              this.$refs.carActivityWrapper.scrollTo(0, 0);
            });
            this.newTask.text = null;
            this.newTask.userId = null;

            this.$refs.todoTaskActivityForm.reset();
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Car todo task failed to add", {
              title: `Failed`,
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    async updateTask(item, index) {
      try {
        this.isLoading = true;

        const res = await this.$axios.put(
          `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/todo-items/${item.id}`,
          { name: item.name, isChecked: item.isChecked }
        );

        await this.$store.commit("car/UPDATE_TODO_ITEM", {
          item: res.data,
          index: index,
        });
        this.$bvToast.toast("Car todo task updated", {
          title: `Success`,
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast("Car todo task failed to update", {
          title: `Failed`,
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteTask(id, index) {
      try {
        this.isLoading = true;

        await this.$axios.delete(
          `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/todo-items/${id}`
        );

        await this.$store.commit("car/DELETE_TODO_ITEM", index);
        this.$bvToast.toast("Car todo task deleted", {
          title: `Success`,
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast("Car todo task failed to delete", {
          title: `Failed`,
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
