<template>
  <main>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        name="brand"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Brand*</span><br />
        <multiselect
          v-model="brand"
          :options="brands"
          :loading="isLoading"
          placeholder="Brand"
          class="multiselect mt-2"
          label="name"
          track-by="name"
          @search-change="searchValue = $event"
        >
          <template slot="placeholder">Brand </template>

          <template slot="option" slot-scope="props">
            <div
              class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
            >
              <span>{{ props.option.name }}</span>
              <button
                class="multiselect__option-delete kt-button-remove-brand"
                :disabled="isLoading"
                @click.stop="deleteBrand(props.option)"
              >
                <i class="far fa-trash-alt mr-2"></i>
              </button>
            </div>
          </template>

          <template slot="noResult">
            <div class="d-flex flex-wrap align-items-center">
              <span>No job brands found: "{{ searchValue }}" </span>
              <button
                id="kt-button-add-brand"
                class="btn btn-primary ml-3"
                :disabled="isLoading"
                @click="addNewBrand"
              >
                <span>Add new<i class="fal fa-plus text-white ml-3"></i></span>
              </button>
            </div>
          </template>
        </multiselect>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Model*</span>
        <br />
        <input
          id="kt-input-car-model"
          v-model="model"
          type="text"
          name="model"
          required
          placeholder="Model"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">VIN Number*</span>
        <br />
        <input
          id="kt-input-car-vin-number"
          v-model="vinNumber"
          type="text"
          name="vinNumber"
          required
          placeholder="VIN number"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider tag="label" v-slot="{ errors }" class="col-12 px-0">
        <span class="w-100 font-weight-bold">Insurance number</span>
        <br />
        <input
          v-model="insuranceNumber"
          type="text"
          name="insuranceNumber"
          placeholder="Insurance number"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="d-flex flex-wrap w-100">
      <validation-provider
        tag="label"
        :name="`insurance-from-date`"
        v-slot="{ errors }"
        class="col-6 px-0 pr-1"
      >
        <span class="w-100 font-weight-bold d-block mb-2">Insurance From</span>
        <date-picker
          v-model="insuranceFrom"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker"
              style="border-color: #5a5c61 !important"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        :name="`insurance-to-date`"
        v-slot="{ errors }"
        class="col-6 px-0 pl-1"
      >
        <span class="w-100 font-weight-bold d-block mb-2">Insurance To</span>
        <date-picker
          v-model="insuranceTo"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker"
              style="border-color: #5a5c61 !important"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <div class="col-12 px-0">
      <validation-provider tag="label" v-slot="{ errors }" class="col-12 px-0">
        <span class="w-100 font-weight-bold">Leasing Price</span>
        <br />
        <input
          v-model="leasingPrice"
          type="text"
          name="leasingPrice"
          placeholder="Leasing Price"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <div class="d-flex flex-wrap w-100">
      <validation-provider
        tag="label"
        :name="`leasing-from-date`"
        v-slot="{ errors }"
        class="col-6 px-0 pr-1"
      >
        <span class="w-100 font-weight-bold d-block mb-2">Leasing From</span>
        <date-picker
          v-model="leasingFrom"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker"
              style="border-color: #5a5c61 !important"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        :name="`leasing-to-date`"
        v-slot="{ errors }"
        class="col-6 px-0 pl-1"
      >
        <span class="w-100 font-weight-bold d-block mb-2">Leasing To</span>
        <date-picker
          v-model="leasingTo"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker"
              style="border-color: #5a5c61 !important"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <div class="col-12 px-0">
      <validation-provider tag="label" v-slot="{ errors }" class="col-12 px-0">
        <span class="w-100 font-weight-bold">Warranty number</span>
        <br />
        <input
          v-model="warrantyNumber"
          type="text"
          name="warranty"
          placeholder="Warranty number"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <!--  NEW: ADD DATE PICKER  -->
    <div class="d-flex flex-wrap w-100">
      <validation-provider
        tag="label"
        :name="`warranty-from-date`"
        v-slot="{ errors }"
        class="col-6 px-0 pr-1"
      >
        <span class="w-100 font-weight-bold d-block mb-2">Warranty From</span>
        <date-picker
          v-model="warrantyFrom"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker"
              style="border-color: #5a5c61 !important"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        :name="`warranty-to-date`"
        v-slot="{ errors }"
        class="col-6 px-0 pl-1"
      >
        <span class="w-100 font-weight-bold d-block mb-2">Warranty To</span>
        <date-picker
          v-model="warrantyTo"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker"
              style="border-color: #5a5c61 !important"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Vehicle owner*</span>
        <br />
        <input
          id="kt-input-owner"
          v-model="owner"
          type="text"
          name="owner"
          required
          placeholder="Owner"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <div class="col-12 px-0">
      <validation-provider
        tag="label"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Registration number*</span>
        <br />
        <input
          id="kt-input-car-registrationNumber"
          v-model="registrationNumber"
          type="text"
          name="registrationNumber"
          required
          placeholder="registrationNumber"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <div class="d-flex flex-wrap w-100">
      <validation-provider
        tag="label"
        :name="`registration-from-date`"
        v-slot="{ errors }"
        class="col-6 px-0 pr-1"
      >
        <span class="w-100 font-weight-bold d-block mb-2"
          >Registration From</span
        >
        <date-picker
          v-model="registrationFrom"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker"
              style="border-color: #5a5c61 !important"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        :name="`registration-to-date`"
        v-slot="{ errors }"
        class="col-6 px-0 pl-1"
      >
        <span class="w-100 font-weight-bold d-block mb-2">Registration To</span>
        <date-picker
          v-model="registrationTo"
          mode="date"
          :model-config="datePickerConfig"
          :disabled="isLoading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100 date-picker"
              style="border-color: #5a5c61 !important"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </date-picker>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-4">
      <span class="col-2 p-0 font-weight-bold">Archived: </span>
      <b-form-checkbox
        id="kt-checkbox-archived"
        v-model="isDisabled"
        name="is-archived"
        size="lg"
        switch
        class="ml-3 mb-3 fu-checkbox"
      >
      </b-form-checkbox>
      <span class="mb-0 ml-5">{{ isDisabled ? "Active" : "Disabled" }}</span>
    </div>
    <div class="col-12 px-0">
      <validation-provider tag="label" v-slot="{ errors }" class="col-12 px-0">
        <span class="w-100 font-weight-bold">Sold</span>
        <br />
        <b-form-radio-group v-model="isSold" name="radio-sub-component">
          <b-form-radio :value="false">No</b-form-radio>
          <b-form-radio :value="true">Yes</b-form-radio>
        </b-form-radio-group>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="col-12 px-0">
      <validation-provider tag="label" v-slot="{ errors }" class="col-12 px-0">
        <span class="w-100 font-weight-bold">Types of transaction</span>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="transactionTypes"
          text-field="name"
          name="flavour-1"
        >
          <b-form-checkbox
            v-for="type in transactionTypesMeta"
            :value="{ id: type.id, name: type.name }"
            :key="type.id"
            >{{ type.name }}</b-form-checkbox
          >
        </b-form-checkbox-group>
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>
    </div>
  </main>
</template>

<script>
import {DatePicker} from "v-calendar";

export default {
  name: "CreateCarForm",
  components: { DatePicker },
  data() {
    return {
      searchValue: "",
      datePickerConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        format: "YYYY-MM-DD",
      },
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    transactionTypesMeta() {
      return this.$store.state.cars.carsMeta.transactionTypes;
    },
    brands() {
      return this.$store.state.cars.carsMeta.brands;
    },
    brand: {
      get() {
        const selectedId = this.$store.getters["car/getCarFieldData"](
          "brandId"
        );
        return this.brands.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "brandId",
          fieldValue: fieldValue.id,
        });
      },
    },
    model: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("model");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "model",
          fieldValue,
        });
      },
    },
    insuranceNumber: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("insuranceNumber");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "insuranceNumber",
          fieldValue,
        });
      },
    },
    vinNumber: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("vinNumber");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "vinNumber",
          fieldValue,
        });
      },
    },
    owner: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("owner");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "owner",
          fieldValue,
        });
      },
    },
    warrantyNumber: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("warrantyNumber");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "warrantyNumber",
          fieldValue,
        });
      },
    },
    isSold: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("isSold");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "isSold",
          fieldValue,
        });
      },
    },
    transactionTypes: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("transactionTypes");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "transactionTypes",
          fieldValue,
        });
      },
    },

    registrationNumber: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("registrationNumber");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "registrationNumber",
          fieldValue,
        });
      },
    },
    isDisabled: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("isDisabled");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "isDisabled",
          fieldValue,
        });
      },
    },
    leasingPrice: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("leasingPrice");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "leasingPrice",
          fieldValue,
        });
      },
    },
    leasingFrom: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("leasingFrom");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "leasingFrom",
          fieldValue,
        });
      },
    },
    leasingTo: {
      get() {
        return this.$store.getters["car/getCarFieldData"]("leasingTo");
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "leasingTo",
          fieldValue,
        });
      },
    },
    insuranceFrom: {
      get() {
        return this.$store.getters["car/getCarFieldData"](
          "insuranceNumberFromDate"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "insuranceNumberFromDate",
          fieldValue,
        });
      },
    },
    warrantyFrom: {
      get() {
        return this.$store.getters["car/getCarFieldData"](
          "warrantyNumberFromDate"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "warrantyNumberFromDate",
          fieldValue,
        });
      },
    },
    insuranceTo: {
      get() {
        return this.$store.getters["car/getCarFieldData"](
          "insuranceNumberToDate"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "insuranceNumberToDate",
          fieldValue,
        });
      },
    },
    warrantyTo: {
      get() {
        return this.$store.getters["car/getCarFieldData"](
          "warrantyNumberToDate"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "warrantyNumberToDate",
          fieldValue,
        });
      },
    },
    registrationFrom: {
      get() {
        return this.$store.getters["car/getCarFieldData"](
          "registrationNumberFromDate"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "registrationNumberFromDate",
          fieldValue,
        });
      },
    },
    registrationTo: {
      get() {
        return this.$store.getters["car/getCarFieldData"](
          "registrationNumberToDate"
        );
      },
      set(fieldValue) {
        this.$store.commit("car/CHANGE_CAR_FIELD", {
          fieldName: "registrationNumberToDate",
          fieldValue,
        });
      },
    },
  },
  methods: {
    async deleteBrand(option) {
      let conf = confirm(`Are you sure to delete ${option.name} brand?`);
      if (conf) {
        this.isLoading = true;
        try {
          const res = await this.$axios.delete(
            `${process.env.VUE_APP_API_URL}/api/car-brands/${option.id}`
          );
          this.$store.commit("cars/SET_BRANDS_DATA", res.data);
          this.$bvToast.toast(res.statusText, {
            title: "Brand was successfully deleted",
            variant: "success",
            solid: true,
          });
        } catch (error) {
          console.error(error);
          this.errorToast(error.message);
        } finally {
          this.isLoading = false;
        }
      }
    },
    async addNewBrand() {
      this.isLoading = true;
      try {
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/car-brands`,
          {
            name: this.searchValue,
          }
        );
        this.brand = res.data.find(({ name }) => name === this.searchValue);
        this.searchValue = "";
        this.$store.commit("cars/SET_BRANDS_DATA", res.data);
      } catch (error) {
        console.error(error);
        this.errorToast(error.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
