import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

/* MODULES */
import users from "@/store/modules/users/users";
import user from "@/store/modules/users/user";
import companies from "@/store/modules/companies/companies";
import company from "@/store/modules/companies/company";
import leadTable from "@/store/modules/boards/leadTable";
import leadBoards from "@/store/modules/boards/leadBoards";
import leadBoard from "@/store/modules/boards/lead-board/leadBoard";
import leadForwarding from "@/store/modules/boards/lead-forwarding/leadForwarding";
import columns from "@/store/modules/boards/lead-board/columns/columns";
import columnRule from "@/store/modules/boards/lead-board/columns/columnRule";
import columnDeal from "@/store/modules/boards/lead-board/columns/columnDeal";
import questionnaire from "@/store/modules/boards/lead-board/questionnaire/questionnaire";
import analytics from "@/store/modules/analytics/analytics";
import clients from "@/store/modules/clients/clients";
import client from "@/store/modules/clients/client";
import cars from "@/store/modules/cars/cars";
import car from "@/store/modules/cars/car";

export default new Vuex.Store({
  modules: {
    users,
    user,
    companies,
    company,
    leadTable,
    leadBoards,
    leadBoard,
    leadForwarding,
    columns,
    columnRule,
    columnDeal,
    questionnaire,
    analytics,
    clients,
    client,
    cars,
    car,
  },
  state: {
    loggedUser: null,
    generatedQrCode: "",
    perPage: 10,
    status: "Active",
    search: "",
    sortBy: null,
    sortOrder: null,
  },
  mutations: {
    CHANGE_STATUS(state, status) {
      state.status = status;
    },
    SET_USER_DATA(state, userData) {
      Vue.set(state, "loggedUser", userData);
    },
    CHANGE_USER_SECRET_SUBMITTED(state, value) {
      state.loggedUser.secretSubmitted = value;
    },
    SET_QR_CODE(state, value) {
      state.generatedQrCode = value;
    },
    CHANGE_SEARCH_STRING(state, value) {
      state.search = value;
    },
    CHANGE_SORT_VARIABLES(state, { sortBy, sortOrder }) {
      state.sortBy = sortBy;
      state.sortOrder = sortOrder;
    },
    CHANGE_SORT_PARAMETER(state, { fieldName, fieldValue }) {
      state[fieldName] = fieldValue;
    },
  },
  actions: {
    resetUserData({ commit }) {
      commit("SET_USER_DATA", null);
      commit("CHANGE_STATUS", "Active");
    },
  },
  getters: {
    isUserLoggedIn: (state) => {
      return state.loggedUser !== null;
    },
    userPermissions: (state) => {
      return state.loggedUser
        ? state.loggedUser.permissions.map((permission) => permission.name)
        : [];
    },
  },
});
