<template>
  <div class="d-flex align-items-center justify-content-between w-100 py-4">
    <div class="d-flex align-items-center">
      <button
        class="fu-button__transparent px-0"
        :disabled="isLoading"
        @click="discardChanges"
      >
        <i
          class="far fa-arrow-alt-circle-left mr-4 fu-navigation__arrow-back"
        ></i>
      </button>
      <h3 class="mb-0">{{ clientTitle }}</h3>
    </div>
    <div
      v-if="!onlyReadClient"
      class="d-flex justify-content-end align-items-center fu-deal__footer"
    >
      <button
        class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
        @click="saveChanges"
        :disabled="isLoading"
      >
        <span><i :class="`far fa-edit mr-2`"></i>Save</span>
      </button>

      <button
        class="btn btn-primary p-3"
        @click="discardChanges"
        :disabled="isLoading"
      >
        <span><i class="far fa-times mr-2"></i>Cancel</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientDetailsHeader",
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    onlyReadClient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    firstName() {
      return this.$store.state.client.clientData.firstName;
    },
    lastName() {
      return this.$store.state.client.clientData.lastName;
    },
    clientTitle() {
      return `View client ${this.firstName}  ${this.lastName}`;
    },
  },

  methods: {
    async saveChanges() {
      this.$emit("saveChanges");
    },
    async discardChanges() {
      const conf = confirm("Do you really want discard all changes?");
      if (conf) await this.$router.go(-1);
    },
  },
};
</script>
