<template>
  <b-modal
    id="documentUploadModal"
    ref="documentUploadModal"
    centered
    content-class="fu-modal"
    header-class="fu-modal__header fu-modal__submit-header"
    body-class="fu-modal__body"
    footer-class="fu-modal__footer"
    @hidden="closeModal"
  >
    <vue-dropzone
      class="col-12"
      ref="myVueDropzone"
      id="dropzone"
      :options="dropzoneOptions"
      @vdropzone-success="fileAdded"
    ></vue-dropzone>
    <template #modal-footer>
      <fu-button @click="closeModal" title="Close" id="addNewRentButton">
      </fu-button>
    </template>
  </b-modal>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "DocumentUploadModal",
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    rentId: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      title: null,
      rentDropzoneOptions: {
        withCredentials: true,
        url: `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/rent/${this.rentId}/upload-document`,
        headers: {
          "XSRF-TOKEN": decodeURIComponent(document.cookie.split("=")[1]),
        },
        maxFilesize: 20,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
      },
      clientDropzoneOptions: {
        withCredentials: true,
        url: `${process.env.VUE_APP_API_URL}/api/clients/${this.$route.params.id}/upload-document`,
        headers: {
          "XSRF-TOKEN": decodeURIComponent(document.cookie.split("=")[1]),
        },
        maxFilesize: 20,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
      },
      carDropzoneOptions: {
        withCredentials: true,
        url: `${process.env.VUE_APP_API_URL}/api/cars/${this.$route.params.id}/upload-document`,
        headers: {
          "XSRF-TOKEN": decodeURIComponent(document.cookie.split("=")[1]),
        },
        maxFilesize: 20,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
      },
    };
  },
  computed: {
    dropzoneOptions() {
      if (this.rentId) return this.rentDropzoneOptions;
      else if (this.type === "car") return this.carDropzoneOptions;
      else return this.clientDropzoneOptions;
    },
  },
  methods: {
    fileAdded(file, response) {
      this.$emit("addDocument", response);
    },
    closeModal() {
      this.$refs.documentUploadModal.hide();
    },
  },
};
</script>

<style scoped></style>
