import lodash from "lodash";

const state = {
  clientData: {
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    taxNumber: "",
    idNumber: "",
    idDocumentNumber: "",
    address: "",
    drivingLicenseNumber: "",
    discount: "",
    folders: [],
    rents: [],
    users: [],
  },
};

const mutations = {
  SET_CLIENT_DATA(state, client) {
    state.clientData = lodash.cloneDeep(client);
  },
  CHANGE_CLIENT_FIELD(state, { fieldName, fieldValue }) {
    state.clientData[fieldName] = fieldValue;
  },
  RESET_CLIENT_DATA(state) {
    state.clientData = {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      taxNumber: "",
      idNumber: "",
      idDocumentNumber: "",
      address: "",
      drivingLicenseNumber: "",
      discount: "",
      folders: [],
      rents: [],
      users: [],
    };
  },
};

const actions = {};

const getters = {
  getClientFieldData: (state) => (fieldName) => {
    return state.clientData[fieldName];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
